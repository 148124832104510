<template>
    <div>

    </div>
</template>

<script>
    import Vue from 'vue'

    export default Vue.extend({

        methods: {
            doesFilterPass() {

            },
            getModel() {

            },
            // eslint-disable-next-line no-unused-vars
            setModel(model) {

            },
            isFilterActive() {

            },
            onFilterChanged() {

            },
            // eslint-disable-next-line no-unused-vars
            onFloatingFilterChanged(model) {

            }

        },
    })
</script>

<style scoped>

</style>