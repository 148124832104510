<template>
  <span class="documents-proposal" ref="wrapper">
   <template v-if="!showTooltip">
      <span v-if="!isBeloil && !isMinsktrans && !isNaftan && !isBelaruskali" ref="documentValue">{{ allDocuments }}</span>
      <template v-else>
        <span ref="documentValue">
          <p @click="clickHandlerDocument(value)"
             class="documents-link"
             v-for="(value,i) in params.value" :key="i">
            {{ value.name }}
          </p>
        </span>

      </template>
    </template>
    <el-popover v-else
                popper-class="documents-tooltip"
                placement="top-start"
                trigger="hover">

    <div class="documents-items">

      <template v-if="isBeloil || isMinsktrans || isNaftan || isBelaruskali">
        <p class="documents-link"
           @click="clickHandlerDocument(value)"
           v-for="(value,i) in params.value" :key="i">
          <template v-if="value">
          {{ value.name }}
          </template>
        </p>
      </template>
      <template v-else>
       <p v-for="(value,i) in params.value" :key="i">
        {{ value.name }}
      </p>
      </template>

    </div>


    <template slot="reference">
      <span v-if="!isBeloil && !isMinsktrans && !isNaftan && !isBelaruskali" ref="documentValue">{{ allDocuments }}</span>
      <template v-else>
        <span ref="documentValue">
          <p @click="clickHandlerDocument(value)"
             class="documents-link"
             v-for="(value,i) in params.value" :key="i">
            {{ value.name }}
          </p>
        </span>
      </template>
    </template>
  </el-popover>
  </span>

</template>

<script>
import Vue from 'vue'
import api from "@/application"

export default Vue.extend({
  name: "DocumentsArrayCellRendererFramework",
  data() {
    return {
      showTooltip: false,
      resizeObserver: null
    }
  },
  computed: {
    allDocuments() {
      let allNameDocuments = ''

      if(!this.params.value)
        return allNameDocuments

      this.params.value.forEach((document,i)=>{
        allNameDocuments += document.name
        if(i+1 !== this.params.value.length)
          allNameDocuments += ', '
      })

      return allNameDocuments
    },
    isBeloil() {
      return this.params.data.proposalSource === "https://sapsrm.beloil.by"
    },
    isMinsktrans() {
      return this.params.data.proposalSource === "https://zakupki.minsktrans.by"
    },
    isNaftan() {
      return this.params.data.proposalSource === "https://naftan.by"
    },
    isBelaruskali() {
      return this.params.data.proposalSource === "https://belaruskali.by"
    }
  },
  mounted() {
    setTimeout(() => {
      this.setVisibleValue()

      if (this.$refs?.wrapper) {
        this.resizeObserver = new ResizeObserver(() => {
          this.setVisibleValue()
        })
        this.resizeObserver.observe(this.$refs.wrapper)
      }
    }, 1000)
  },
  methods: {
    setVisibleValue() {
      const el = this.$refs.documentValue
      const elParent = this.$refs.wrapper

      if (el && elParent) {

        const paddingLrEl = (parseInt(window.getComputedStyle(elParent).paddingLeft) +
            parseInt(getComputedStyle(elParent).paddingRight))

        this.showTooltip = (elParent.clientHeight < el.offsetHeight) ||
            ((elParent.clientWidth - paddingLrEl) < el.offsetWidth) ||
            (el.scrollHeight > el.clientHeight) ||
            (el.scrollWidth > el.clientWidth)
      }
    },
    clickHandlerDocument(file) {
      if(this.isMinsktrans || this.isBelaruskali)
        this.openFileInNewTab(file)
      else if(this.isBeloil)
        this.downloadFile(file)
    },
    openFileInNewTab(file) {
      const link = document.createElement('a')
      link.href = file.url
      link.setAttribute('target', '_blank')
      link.click()
    },
    async downloadFile(file) {
      try {

        let params = {};

        new URL(file.url).searchParams.forEach( (val, key) => {
          params[key] = val;
        })

        if('d' in params)
          params.d = encodeURIComponent(params.d)

        const blob = await api.documents.downloadFileForLot(params)

        const link = URL.createObjectURL(blob.data)
        const a = document.createElement('a')
        a.href = link
        a.setAttribute('download', file.name)
        a.click()
        window.URL.revokeObjectURL(link)
      } catch (error) {
        this.$toast.error('Что-то пошло не так, попробуйте позже')
        throw error
      }
    }
  }
})
</script>

<style lang="less">
.documents-proposal {
  display: block;

  .documents-link {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}

.documents-tooltip {
  padding: 4px 8px;
  font-family: Roboto, sans-serif;
  color: #F7F7F7;
  letter-spacing: 0.3px;
  background-color: rgba(121, 145, 156, 0.9);
  line-height: 16px;
  word-break: break-word;
  border: none;
  max-width: 290px;

  width: max-content !important;
  min-width: 40px !important;
  text-align: left;

  .popper__arrow {
    display: none;
  }

  .documents-link {
    color: #F7F7F7;
    margin-top: 6px;
    word-break: break-all;
    display: block;

    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &:last-child {
      margin-bottom: 6px;
    }
  }

}
</style>