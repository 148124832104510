export const agGreedLocaleRu = {
    
    // Set Filter
    selectAll: "Выбрать все",
    selectAllSearchResults: '(Выбрать все результаты поиска)',
    searchOoo: "Поиск...",
    blanks: "Пусто",
    noMatches: 'Нет совпадений',
    
    // Number Filter & Text Filter
    filterOoo: "Фильтровать...",
    equals: "Эквивалентно",
    notEqual: 'Не эквивалентно',
    empty: 'Выберите один',
    
    // Number Filter
    lessThan: "Меньше, чем",
    greaterThan: "Больше, чем",
    lessThanOrEqual: 'Меньше или эквивалентно',
    greaterThanOrEqual: 'Больше или эквивалентно',
    inRange: 'В диапазоне',
    inRangeStart: 'В начале диапазона',
    inRangeEnd: 'В конце диапазона',
    
    // Text Filter
    contains: "Содержит",//
    notContains: 'Не содержит',
    startsWith: "Начинается с",
    endsWith: "Заканчивается на",
    
    // Date Filter
    dateFormatOoo: 'dd-mm-yyyy',
    
    // Filter Conditions
    andCondition: 'И',
    orCondition: 'ИЛИ',
    
    // Filter Buttons
    applyFilter: "Применить фильтр",
    resetFilter: 'Сбросить фильтр',
    clearFilter: 'Очистить фильтр',
    cancelFilter: 'Отменить фильтр',
    
    // Filter Titles
    textFilter: 'Фильтр текста',
    numberFilter: 'Фильтр чисел',
    dateFilter: 'Фильтр дат',
    setFilter: 'Установить фильтр',
    
    // Side Bar
    columns: 'Столбцы',
    filters: 'Фильтры',
    
    // Columns tool panel
    pivotMode: "Режим сводной таблицы",
    groups: "Группировка по строкам",
    rowGroupColumnsEmptyMessage: "Перетяните сюда для группировки по строкам",
    values: "Значения",
    valueColumnsEmptyMessage: "Перетяните сюда для агрегации",
    pivots: "Заголовки столбцов",
    pivotColumnsEmptyMessage: "Перетяните сюда, чтобы задать загаловки столбцов",
    
    // Header of the Default Group Column
    group: "Группа",
    loadingOoo: "Загрузка...",
    enabled: 'Включено',
    
    // Other
    noRowsToShow: "Нет данных",
    
    // Menu
    pinColumn: "Закрепить колонку",
    pinLeft: "Закрепить слева",
    pinRight: "Закрепить справа",
    noPin: "Не закреплять",
    valueAggregation: "Агрегация по значению",
    autosizeThiscolumn: "Задать авторазмер этой колонки",
    autosizeAllColumns: "Задать авторазмер всех колонок",
    groupBy: "Группировать по",
    ungroupBy: "Разгруппировать по",
    resetColumns: "Сбросить столбцы",
    expandAll: "Развернуть все",
    collapseAll: "Свернуть все",
    copy: "Копировать",
    copyWithHeaders: "Копировать с заголовком",
    ctrlC: "Ctrl+C",
    paste: "Вставить",
    ctrlV: "Ctrl+V",
    export: "Экспорт",
    csvExport: "Экспорт в CSV",
    excelExport: 'Экспорт в Excel (.xlsx)',
    excelXmlExport: 'Экспорт в Excel (.xml)',
    
    // Enterprise Menu Aggregation and Status Bar
    sum: "Сумма",
    min: "Минимум",
    max: "Максимум",
    none: "Пусто",
    count: "Количество",
    avg: "Усредненное значение",
    page: "Страница",
    more: "ещё",
    to: "-",
    of: "из",
    nextPage: "Следующая страница",
    lastPage: "Последняя страница",
    firstPage: "Первая страница",
    previousPage: "Предыдущая страница",
    filteredRows: 'Отфильтрованные строки',
    selectedRows: 'Выбранные строки',
    totalRows: 'Всего строк',
    totalAndFilteredRows: 'Всего и отфильтрованных строк',
    
    toolPanel: "Панель инструментов",
    first: "Первый",
    last: "Последний",
    rowGroupColumns: "Столбцы группировки по строкам",
    valueColumns: "Столбцы со значениями",
    toolPanelButton: "Панель инструментов",
}
