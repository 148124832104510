<template>
    <div
            class="purchases-table-wrapper"
            ref="purchasesTable"
    >
        <ag-grid-vue style="width: 100%; height: 100%"
                     class="ag-theme-alpine"
                     :gridOptions="gridOptions"
                     :columnDefs="columns"
                     :rowData="purchases"
                     :suppressDragLeaveHidesColumns="true"
                     :rowSelection="rowSelection"
                     :suppressRowClickSelection="true"
                     :localeText="localeText"
                     :enableCellTextSelection="true"
                     :suppressNoRowsOverlay="true"
                     :overlayLoadingTemplate="overlayLoadingTemplate"
                     :tooltipShowDelay="tooltipShowDelay"
        >
        </ag-grid-vue>
    </div>
</template>

<script>
    import {eventBus} from "@/main"
    import {AgGridVue} from 'ag-grid-vue'
    import {mapActions, mapGetters} from 'vuex'
    import {moveColumns, sortedTableColumns} from "@/utils/agGreed/tableSettingsHelper"
    import purchasesTableRowsHeight from '@/utils/agGreed/purchasesTableRowsHeight'
    import SpanCellRendererFramework from "./cellRenderers/SpanCellRendererFramework"
    import CustomFloatingFilterComponent from "./filters/CustomFloatingFilterComponent"
    import CustomFilterComponent from "./filters/CustomFilterComponent"
    import CellTooltip from '@/components/purchaseTable/cellRenderers/CellTooltip'
    import {agGridColumnsDefs} from "@/utils/agGreed/agGridColumnsDefs"
    import {agGreedLocaleRu} from "@/utils/agGreed/agGreedLocaleRu"
    import CustomSortingHeaderComponent from "./headers/CustomSortingHeaderComponent"
    import {isEmpty, isNil} from "lodash"
    import {purchasesPath} from "@/router/purchases"

    export default {
        name: "PurchasesTable",
        props: ['purchases', 'isRequesting'],
        data() {
            return {
                gridOptions: null,
                gridApi: null,
                gridColumnApi: null,
                rowSelection: 'multiple',
                localeText: agGreedLocaleRu,
                tooltipShowDelay: 0,
                overlayLoadingTemplate: null,
                timer: null,
                clientWidth: window.innerWidth,
            }
        },
        components: {
            AgGridVue,
        },
        computed: {
            ...mapGetters('purchasesTableSettings', ['purchasesTableColumns', 'lineHeightsType', 'getColumnProperty']),
            ...mapGetters('columnsFilters', ['columnFiltersByKey']),
            ...mapGetters('columnsSort', ['columnSortByKey']),
            ...mapGetters('purchasesScroll', ['scroll']),
            ...mapGetters('filterTabs', ['currentTab']),
            columns() {
                return sortedTableColumns(this.purchasesTableColumns, agGridColumnsDefs, this.resolutionMultiplier)
            },
            resolutionMultiplier() {
                const resolutionMap = {
                    '1920': 1,
                    '1440': .94,
                    '1368': .87,
                    '1280': .8,
                }

                const getResolutionWidth = (width) => {
                    if (width > 1919) {
                        return '1920'
                    }
                    if (width >= 1439) {
                        return '1440'
                    }
                    if (width >= 1367) {
                        return '1368'
                    }
                    if (width >= 1279) {
                        return '1280'
                    }
                    return '1280'
                }

                return resolutionMap[getResolutionWidth(this.clientWidth)]
            }
        },
        methods: {
            ...mapActions('purchasesTableSettings', ['updatePurchaseTableColumns', 'updatePurchaseTableColumn', 'updateLineHeightsType']),
            ...mapActions('purchasesScroll', ['setScroll']),
            ...mapActions('columnsFilters', ['removeColumnFilters']),
            ...mapActions('columnsSort', ['removeColumnSort']),
            ...mapActions('filterTabs', ['setZeroAllLots']),
            async toggleColumnVisibility(columnName) {
                // sorry :D
                const isVisible = this.gridOptions.columnApi.getColumn(columnName).visible
                this.gridColumnApi.setColumnVisible(columnName, !isVisible)
                const columnData = {
                    columnName,
                    columnProperty: 'hide',
                    value: isVisible
                }

                await this.updatePurchaseTableColumn(columnData)
                if (!isNil(this.columnFiltersByKey(columnName)) && !isEmpty(this.columnFiltersByKey(columnName))) {
                    this.removeColumnFilters(columnName)
                }
                if (!isNil(this.columnSortByKey(columnName)) && !isEmpty(this.columnSortByKey(columnName))) {
                    this.removeColumnSort(columnName)
                }
                this.pushRoute()
            },
            moveColumnByIndex(fromIndex, toIndex) {
                this.gridColumnApi.moveColumnByIndex(fromIndex, toIndex)
            },
            pushRoute() {
                if (isNil(this.$route.params.id)) {
                    return
                }
                if (parseInt(this.$route.params.id) === this.currentTab.id && isEmpty(this.$route.query)) {
                    return
                }
                this.$router.push(`${purchasesPath}/${this.currentTab.id}`)
                this.setZeroAllLots(this.currentTab)
            },
            onResize() {
                this.clientWidth = window.innerWidth
            }
        },
        watch: {
            lineHeightsType: function (newVal) {
                this.gridOptions.rowHeight = newVal.height
                this.gridApi.resetRowHeights()
                this.gridApi.redrawRows()
            },
            isRequesting: function (newVal) {
                if (newVal) {
                    clearTimeout(this.timer)
                    this.timer = null
                    this.gridApi.showLoadingOverlay()
                    return
                }

                this.gridApi.hideOverlay()
            },
            purchases: function () {
                this.$refs.purchasesTable.classList.add('new')
                clearTimeout(this.timer)
                this.timer = setTimeout(() => {
                    this.$refs.purchasesTable.classList.remove('new')
                    this.timer = null
                }, 5000)
            }
        },
        created() {
            eventBus.$on('toggleColumnVisibility', this.toggleColumnVisibility)
            eventBus.$on('moveColumnByIndex', this.moveColumnByIndex)
        },
        beforeMount() {

            this.overlayLoadingTemplate =
                '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>'
            const movedColumn = {
                oldIndex: null,
                toIndex: null,
                columnName: ''
            }
            this.gridOptions = {
                floatingFiltersHeight: 54,
                defaultColDef: {
                    enableCellTextSelection: true,
                    filter: true,
                    filterFramework: CustomFilterComponent,
                    floatingFilter: true,
                    suppressMenu: true,
                    headerComponentFramework: CustomSortingHeaderComponent,
                    cellRendererFramework: SpanCellRendererFramework,
                    floatingFilterComponentFramework: CustomFloatingFilterComponent,
                    tooltipComponentFramework: CellTooltip,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    resizable: true,
                    lockPinned: true,
                },
                rowHeight: this.lineHeightsType.height,
                rowClassRules: {
                    'row-height-sm': (params) => params.node.rowHeight === purchasesTableRowsHeight.oneRow.height,
                    'row-height-md': (params) => params.node.rowHeight === purchasesTableRowsHeight.twoRows.height,
                    'row-height-lg': (params) => params.node.rowHeight === purchasesTableRowsHeight.threeRows.height,
                    'row-new': (params) => params.node.data.isNew,
                    'row-updated': (params) => params.node.data.isUpdated
                },
                onColumnResized: (e) => {
                    if (!e.column) {
                        return
                    }
                    if (e.finished) {
                        const columnData = {
                            columnName: e.column.colDef.field,
                            columnProperty: 'width',
                            value: Math.round(e.column.actualWidth / this.resolutionMultiplier)
                        }
                        this.updatePurchaseTableColumn(columnData)
                    }
                },
                onColumnMoved: ({column, toIndex}) => {
                    const {colDef: {field}} = column
                    movedColumn.oldIndex = this.getColumnProperty(field, 'index')
                    movedColumn.columnName = field
                    movedColumn.toIndex = toIndex
                },
                onDragStopped: () => {
                    if (movedColumn.toIndex !== null) {
                        const updatedColumns = moveColumns(this.purchasesTableColumns, movedColumn)
                        this.updatePurchaseTableColumns(updatedColumns)
                        movedColumn.oldIndex = null
                        movedColumn.toIndex = null
                        movedColumn.columnName = ''
                    }
                },
                onRowDataChanged: () => {
                    document.querySelector('div[ref="eBodyViewport"]').scrollTop = this.scroll
                    this.setScroll(0)
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            })
            this.gridApi = this.gridOptions.api
            this.gridColumnApi = this.gridOptions.columnApi
        },
        beforeDestroy() {
            clearTimeout(this.timer)
            window.removeEventListener('resize', this.onResize);
            eventBus.$off('toggleColumnVisibility')
            eventBus.$off('moveColumnByIndex')
        }
    }
</script>

<style lang="less">
    @import '../../assets/less/variables.less';
    @import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
    @import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-ellipsis div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #e5a300;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    .lds-ellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
    }

    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }

    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }


    .ag-theme-alpine {
        font-size: 14px;
        font-family: @font;
        text-align: left;
        background-color: transparent;

        .ag-root-wrapper {
            border: none;
        }

        .ag-header {
            border-bottom: none;
            background-color: #ffffff;
        }

        .ag-header-row {
            border-bottom: 2px solid @bg-color-light;
        }

        .ag-header-row:not(:first-child) .ag-header-cell,
        .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
            border-top: 0;
        }

        .ag-header-cell {
            padding: 0 12px;
            @media (max-width: 1440px) {
                padding: 0 8px;
            }
            @media (max-width: 1368px) {
                padding: 0 4px;
            }
            font-weight: 500;
            color: @text-color-light;
            font-size: 13px;

            &.checkbox-cell {
                padding: 8px;
            }

            &.favorites-cell,
            &.hidden-cell {
                padding: 8px 0;
            }

            .ag-filter-icon {
                display: none;
            }
        }

        .ag-header-cell-resize::after {
            background-color: @bg-color-middle;
        }

        .ag-header-row-floating-filter {
            background-color: @bg-color-light;
        }

        .ag-cell {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            border-top: none;
            border-bottom: none;
            padding: 8px 12px;
            @media (max-width: 1440px) {
                padding: 8px 8px;
            }
            @media (max-width: 1368px) {
                padding: 8px 4px;
            }
            line-height: 1.7;
            font-size: 13px;

            &.checkbox-cell {
                padding: 8px;
            }

            &.favorites-cell {
                padding: 8px 0;
            }

            &.hidden-cell {
                padding: 8px 0 8px 4px;

                span {
                    margin-left: auto;
                }
            }
        }

        .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
            border-color: transparent;
        }

        .ag-checkbox-input-wrapper {
            width: 24px;
            height: 24px;
            background-color: transparent;

            &:focus-within,
            &:active {
                box-shadow: none;
            }

            &::after {
                content: '';
                width: 24px;
                height: 24px;
                background-image: url("../../assets/img/ico-checkbox-unchecked.svg");
            }

            &.ag-indeterminate {
                &::after {
                    content: '';
                    background-image: url("../../assets/img/ico-checkbox-partly.svg");
                }
            }

            &.ag-checked {
                &::after {
                    content: '';
                    background-image: url("../../assets/img/ico-checkbox-checked-grey.svg");
                }
            }

            input {
                cursor: pointer;
            }
        }

        .ag-row {
            border-width: 0;
            border-bottom: 2px solid @bg-color-light;
            background-color: transparent;

            .hide-purchase-btn {
                visibility: hidden;
                opacity: 0;
            }

            &.ag-row-selected,
            &.ag-row-odd {
                background-color: transparent;
            }

            &.ag-row-hover {
                background-color: fade(@bg-color-light, 50%);

                .hide-purchase-btn {
                    visibility: visible;
                    opacity: 1;
                }
            }

            &.row-updated {
                background-color: fade(@warning-color, 20%);

                &.ag-row-hover {
                    background-color: fade(@bg-color-light, 50%);
                }
            }
        }

        .row-height-sm {
            .ag-cell {
                .ag-cell-value > a,
                .ag-cell-value > span,
                .ag-cell-value > p,
                .proposal {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        .row-height-md {
            .ag-cell {
                .ag-cell-value > a,
                .ag-cell-value > span,
                .ag-cell-value > p,
                .proposal > span {
                    overflow: hidden;
                    display: -webkit-box;
                    white-space: normal;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    word-wrap: break-word;
                }
            }
        }

        .row-height-lg {
            .ag-cell {
                .ag-cell-value > a,
                .ag-cell-value > span,
                .ag-cell-value > p,
                .proposal > span {
                    overflow: hidden;
                    display: -webkit-box;
                    white-space: normal;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    word-wrap: break-word;
                }
            }
        }

        ::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background-color: #F7F7F7;
            border-radius: 6px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #D3D3D3;
            border-radius: 6px;
            box-shadow: inset 1px 1px 10px #D3D3D3;

            &:hover {
                background-color: @text-color-light;
            }
        }

    }

    .new .ag-theme-alpine .ag-row {
        &.row-new {
            background-color: @bg-color-light;
        }

        &.ag-row-odd {
            &.row-new {
                background-color: @bg-color-light;
            }
        }
    }
</style>
