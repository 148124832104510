<template>
    <button
            type="button"
            @click="onClickDraggableFilterTabsBtn"
            class="draggable-filter-tabs-list-btn"
    >
                <span v-if="isDraggableTabFiltersMenuOpen">
                    <img src="../../assets/img/expand_less-black-24dp.svg"/>
                </span>
                <span v-else>
                    <img src="../../assets/img/expand_more-black-24dp.svg"/>
                </span>
    </button>
</template>

<script>
    export default {
        name: "DraggableFilterTabsListBtn",
        props: ['isDraggableTabFiltersMenuOpen'],
        methods: {
            onClickDraggableFilterTabsBtn() {
                this.$emit('onClickDraggableFilterTabsBtn')
            }
        }
    }
</script>

<style scoped>
    .draggable-filter-tabs-list-btn {
        width: 48px;
        height: 48px;
    }
</style>