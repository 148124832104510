<template>
    <span class="hint-label">
        {{label}}
        <span class="hint-label-btn">
            <ico name="icoCancel" size="16" color="#79919C"/>
        </span>
    </span>
</template>

<script>
    export default {
        name: "HintLabel",
        props: {
            label: {
                type: String,
                required: true,
                default: '',
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/less/variables";

    .hint-label {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 12px;
        padding: 0 4px 0 8px;
        height: 20px;
        font-size: 12px;
        color: @text-color;
        background-color: @bg-color-middle;

        &-btn {
            flex-shrink: 0;
            flex-grow: 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-left: 4px;
            border-radius: 50%;
            width: 16px;
            height: 16px;
        }
    }
</style>