<template>
    <div class="filtersContainer">
        <div class="filters">
            <selected-filter-item
                    v-for="(item, id) in columnFiltersByKey(field)"
                    :id="id"
                    :key="id"
                    :filter-item="item"
                    @deleteFilter="deleteFilter"
                    @editFilter="editFilter"
            />
        </div>
        <div class="inputContainer">
            <span>
                <ico name="icoSearch" />
            </span>
            <input
                    v-if="!type"
                    type="text"
                    class="filterInput"
                    v-model="currentValue"
                    @keyup.enter.exact="onEnterPressed"
                    :disabled="disabled"
            />
            <input
                    v-else-if="type === 'date'"
                    type="text"
                    class="filterInput"
                    v-model="currentValue"
                    @keyup.enter.exact="onEnterPressed"
                    :disabled="disabled"
                    v-mask="{mask: '99.99.9999', placeholder: 'дд.мм.гггг', showMaskOnHover: false}"
            >
            <input
                    v-else-if="type === 'number'"
                    type="text"
                    class="filterInput"
                    v-model="currentValue"
                    @keyup.enter.exact="onEnterPressed"
                    :disabled="disabled"
                    v-mask="{mask: '999', placeholder: '', showMaskOnHover: false, greedy: true}"
            >
            <input
                    v-else-if="type === 'decimal'"
                    type="text"
                    class="filterInput"
                    v-model="currentValue"
                    @keyup.enter.exact="onEnterPressed"
                    :disabled="disabled"
            >
            <button
                    v-if="editableFilterId"
                    @click="cancelEdit"
                    type="button"
            >
                <ico name="icoCancel" size="16" color="#79919C"/>
            </button>

        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import {uuid} from 'vue-uuid'
    import SelectedFilterItem from "../../common/SelectedFilterItem"
    import {mapActions, mapGetters} from "vuex"
    import {dateValid} from "@/utils/datetime"
    import {isEmpty, values} from "lodash"
    import {purchasesPath} from "@/router/purchases"
    import {eventBus} from "@/main"

    export default Vue.extend({
        name: "CustomFloatingFilterComponent",
        components: {
            SelectedFilterItem
        },
        data() {
            return {
                currentValue: '',
                editableFilterId: null,
                filterPath: null,
                type: null,
                field: null,
                isValid: false,
            }
        },
        computed: {
            ...mapGetters('columnsFilters', ['columnFiltersByKey']),
            ...mapGetters('auth', ['authorities']),
            ...mapGetters('filterTabs', ['currentTab']),
            disabled() {
                return !this.authorities.includes('ROLE_SUBSCRIBED_USER') &&
                    (
                        (values(this.columnFiltersByKey(this.field)).length > 0 && !this.editableFilterId)
                    )
            },
        },
        methods: {
            ...mapActions('columnsFilters', ['addColumnFilter', 'removeColumnFilter']),
            ...mapActions('filterTabs', ['setZeroAllLots']),
            cancelEdit() {
                this.clearState()
            },
            onEnterPressed(event) {

                switch (this.type) {
                    case 'number':
                        this.onChangeNumberInput(event)
                        break
                    case 'decimal':
                        this.onChangeDecimalInput(event)
                        break
                    case 'date':
                        this.onChangeDateInput(event)
                        break
                    default:
                        this.onChangeInput(event)
                }

                if (this.isValid) {
                    this.pushRoute()
                }
            },
            onChangeDateInput(event) {
                if (dateValid(event.target.value)) {
                    this.isValid = true
                    this.setNewCurrentValue(event.target.value)
                    this.clearState()
                    return
                }
                this.isValid = false
            },
            onChangeNumberInput(event) {
                if (event.target.value !== '') {
                    this.isValid = true
                    this.setNewCurrentValue(event.target.value)
                    this.clearState()
                    return
                }
                this.isValid = false
            },
            onChangeDecimalInput(event) {
                if (/^\d+(?:\.\d{2})?$/.test(event.target.value)) {
                    this.isValid = true
                    this.setNewCurrentValue(event.target.value)
                    this.clearState()
                    return
                }
                this.isValid = false
            },
            onChangeInput(event) {
                if (event.target.value !== '') {
                    this.isValid = true
                    this.setNewCurrentValue(event.target.value)
                    this.clearState()
                    return
                }
                this.isValid = false
            },
            setNewCurrentValue(value) {
                const id = this.editableFilterId ?? uuid.v4()

                this.addColumnFilter({
                    id,
                    value: {
                        text: value
                    },
                    field: this.field,
                    filterPath: this.filterPath,
                    operation: this.operation,
                })
                this.pushRoute()

            },
            onParentModelChanged() {

            },
            deleteFilter(id) {
                this.removeColumnFilter({
                    id,
                    field: this.field,
                    filterPath: this.filterPath,
                    operation: this.operation,
                })
                this.clearState()
                this.pushRoute()
            },
            editFilter(id, text) {
                this.editableFilterId = id
                this.currentValue = text
                this.isValid = true
            },
            clearState() {
                this.currentValue = ''
                this.editableFilterId = null
                this.isValid = false
            },
            pushRoute() {
                if (parseInt(this.$route.params.id) === this.currentTab.id && isEmpty(this.$route.query)) {
                    this.setZeroAllLots(this.currentTab)
                    return
                }
                this.$router.push(`${purchasesPath}/${this.currentTab.id}`)
                this.setZeroAllLots(this.currentTab)
            },
            resetFilterInput() {

                this.currentValue = ''
            },
        },
        created() {
            this.type = this.params.type
            this.filterPath = this.params.column.colDef.filterPath
            this.field = this.params.column.colDef.field
            this.operation = this.params.column.colDef.operation
            eventBus.$on('resetFilterInput', this.resetFilterInput)
        },
        beforeDestroy() {
            eventBus.$off('resetFilterInput', this.resetFilterInput)
        }
    })
</script>

<style lang="less" scoped>
    @import '../../../assets/less/variables.less';

    ::-webkit-scrollbar {
        width: 2px;
        height: 2px;
    }

    .filtersContainer {
        padding-top: 2px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
    }

    .filters {
        flex-shrink: 0;
        height: 26px;
        display: flex;
        overflow: auto;
        white-space: nowrap;
        flex-wrap: wrap;
    }

    .filterInput {
        width: 100%;
        padding: 2px 4px;
        border-right: 0;
        border-left: 0;
        border-top: 0;
        border-bottom-color: @text-color-lighter;
        letter-spacing: 0.3px;
        background-color: transparent;

        &:focus {
            border-bottom-color: @text-color-light;
        }
    }

    .filterInput:focus {
        outline: none;
    }

    .inputContainer {
        display: flex;
    }

</style>
