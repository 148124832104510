<template>
    <div class="modal-content">
        <form class="form-relative" novalidate @submit.prevent="createFilter">
            <div ref="content" class="form-in-scroll">
                <ErrorMessage v-if="errorMessage" :error-message="errorMessage"></ErrorMessage>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label class="form-control-label">Название фильтра<span>*</span></label>
                            <input
                                    :value="formData.name"
                                    @change="updateParameter($event.target.value, 'name')"
                                    @input="removeSpaces($event)"
                                    type="text"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.formData.name.$error}"
                                    placeholder="Название фильтра"
                                    required
                                    data-v-step="filter-name-input"
                                    ref="filterNameInput"
                            >
                            <div
                                    v-if="$v.formData.name.$dirty && !$v.formData.name.required"
                                    class="invalid-feedback"
                            >
                                Поле обязательно для заполнения
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group form-group-budget">
                            <label class="form-control-label">Бюджет лота</label>
                            <div class="row">
                                <div class="col-6 budget">
                                    <input
                                            type="text"
                                            class="form-control form-control-light"
                                            :class="{'is-invalid': isBudgetInvalid}"
                                            placeholder="от"
                                            :value="formData.budgetFrom"
                                            @focus="isBudgetInvalid = false"
                                            @change="updateParameter($event.target.value, 'budgetFrom')"
                                            v-mask="{
                                        alias: 'decimal',
                                        inputMode: 'numeric',
                                        groupSeparator: ' ',
                                        placeholder: '',
                                        showMaskOnHover: false,
                                        rightAlign: false
                                    }"
                                    >
                                </div>
                                <div class="col-6">
                                    <input
                                            type="text"
                                            class="form-control form-control-light"
                                            :class="{'is-invalid': isBudgetInvalid}"
                                            placeholder="до"
                                            :value="formData.budgetTo"
                                            @focus="isBudgetInvalid = false"
                                            @change="updateParameter($event.target.value, 'budgetTo')"
                                            v-mask="{
                                        alias: 'decimal',
                                        inputMode: 'numeric',
                                        groupSeparator: ' ',
                                        placeholder: '',
                                        showMaskOnHover: false,
                                        rightAlign: false
                                    }"
                                    >
                                </div>
                            </div>
                            <div
                                    v-if="this.isBudgetInvalid"
                                    class="invalid-feedback"
                            >
                                Нижняя граница заданного бюджета не может превышать верхнюю границу
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label class="form-control-label">Вид закупки</label>
                            <EntitySelect
                                    :value="formData.purchasesTypes"
                                    :entities="purchasesTypes"
                                    :multiple="true"
                                    :placeholder="'Все'"
                                    :is-updatable="true"
                                    @onChange="onChangePurchaseType"
                                    @onLoad="getProceduresTypes"
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label class="form-control-label">Площадка</label>
                            <EntitySelect
                                    :value="formData.placesTypes"
                                    :entities="placesTypes"
                                    :multiple="true"
                                    :placeholder="'Все'"
                                    :is-updatable="true"
                                    @onChange="onChangePlaceType"
                                    @onLoad="getPlacesTypes"
                            />
                        </div>
                    </div>
                </div>
              <div class="form-group">
                <label class="form-control-label">Отрасль</label>
                <EntitySelect
                    :value="formData.sectorsTypes"
                    :entities="sectorsTypes"
                    :multiple="true"
                    :placeholder="'Все'"
                    :is-updatable="true"
                    @onChange="onChangeSectorType"
                    @onLoad="getSectorsTypes"
                />
              </div>
                <div class="form-group">
                    <div class="form-control-label-wrapper">
                        <label class="form-control-label">Ключевые слова</label>
                        <Hint>
                            <key-words-hint/>
                        </Hint>
                    </div>
                    <MultipleInput
                            @updateFilter="updateParameter(...arguments, 'keyWords')"
                            :placeholder="'Ключевые слова для фильтрации закупок'"
                            :isVertical="true"
                            :selected-values="formData.keyWords"
                            data-v-step="filter-keywords-multiple-input"
                    ></MultipleInput>
                </div>
                <div class="form-group">
                    <div class="form-control-label-wrapper">
                        <label class="form-control-label">Исключения</label>
                        <Hint>
                            <exclude-words-hint/>
                        </Hint>
                    </div>
                    <MultipleInput
                            @updateFilter="updateParameter(...arguments, 'excludeWords')"
                            :placeholder="'Ключевые слова, которые будут исключены из поиска'"
                            :isVertical="true"
                            :selected-values="formData.excludeWords"
                            data-v-step="filter-exclude-words-multiple-input"
                    ></MultipleInput>
                </div>
                <div class="form-group">
                    <div class="form-control-label-wrapper">
                        <label class="form-control-label">Регион</label>
                        <Hint>
                            <region-words-hint/>
                        </Hint>
                    </div>
                    <MultipleInput
                            @updateFilter="updateParameter(...arguments, 'regions')"
                            :placeholder="'Укажите интересующие регионы'"
                            :isVertical="true"
                            :selected-values="formData.regions"
                            data-v-step="filter-region-multiple-input"
                    ></MultipleInput>
                </div>
                <div class="form-group">
                    <label class="form-control-label">Название организации</label>
                    <MultipleInput
                            @updateFilter="updateParameter(...arguments, 'organizations')"
                            :placeholder="'Введите ключевые слова для фильтрации организаций'"
                            :isVertical="true"
                            :selected-values="formData.organizations"
                    ></MultipleInput>
                </div>
                <div class="form-group">
                    <div class="form-control-label-wrapper">
                        <label class="form-control-label">Код ОКРБ</label>
                        <Hint>
                            <div class="hint-content" style="min-width: 300px;">
                                <p>Код ОКРБ имеет специальный формат ХХ.ХХ.ХХ.ХХХ и отличается в зависимости от группы
                                    товаров</p>
                            </div>
                        </Hint>
                    </div>
                    <MultipleInput
                            @updateFilter="updateParameter(...arguments, 'codesOKRB')"
                            :placeholder="'Коды ОКРБ'"
                            :isVertical="true"
                            :inputmask="{
                                        mask: '99[.99][.99][.999]',
                                        greedy: false,
                                        placeholder: '',
                                        showMaskOnHover: false,
                                    }"
                            :selected-values="formData.codesOKRB"
                    ></MultipleInput>
                </div>
            </div>
            <div class="form-footer form-footer-fixed">
                <button
                        class="btn btn-middle"
                        type="button"
                        :disabled="isDisabled"
                        :title="isDisabled ? 'Введите название фильтра' : ''"
                        @click="componentProps.mode === 'Create' ? createFilter() : editFilter()"
                        data-v-step="create-filter-confirm-button"
                >
                    {{componentProps.mode === 'Create' ? 'Создать фильтр' : 'Сохранить фильтр'}}
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    import {required} from 'vuelidate/lib/validators'
    import Hint from '@/components/common/Hint'
    import MultipleInput from '@/components/purchasesTableCustomFilter/MultipleInput'
    import {mapActions, mapGetters} from "vuex"
    import EntitySelect from "../common/EntitySelect"
    import ErrorMessage from "@/components/common/ErrorMessage"
    import {generateTabFilters} from "@/utils/rsql"
    import {and} from "rsql-builder"
    import {createFilterData} from "@/utils/mapper"
    import {purchasesPath} from "@/router/purchases"
    import KeyWordsHint from "../hints/KeyWordsHint";
    import ExcludeWordsHint from "../hints/ExcludeWordsHint";
    import RegionWordsHint from "../hints/RegionWordsHint";

    export default {
        name: "purchasesTableCustomFilterModal",
        props: ['componentProps'],
        data() {
            return {
                formData: {},
                isBudgetInvalid: false,
                errorMessage: null,
            }
        },
        components: {
            RegionWordsHint,
            ExcludeWordsHint,
            KeyWordsHint,
            EntitySelect,
            Hint,
            MultipleInput,
            ErrorMessage
        },
        validations: {
            formData: {
                name: {
                    required
                },
            },

        },
        computed: {
            ...mapGetters('enums', ['purchasesTypes', 'placesTypes', 'sectorsTypes']),
            ...mapGetters('profile', ['profileId', 'hintsEnabled']),
            ...mapGetters('filterTabs', ['filterTabByIndex', 'filterTabByParam']),
            isDisabled: function () {
                return !this.formData.name
            },
        },
        methods: {
            ...mapActions('filterTabs', ['createFilterTab', 'getFilterTabs', 'setCurrentTab', 'editFilterTab']),
            ...mapActions('enums', ['getProceduresTypes', 'getPlacesTypes', 'getSectorsTypes']),
            removeSpaces(event) {
                if(event.target.value.charAt(0) === ' ') {
                    event.target.value = ''
                }
            },
            compareBudget() {
                const budgetFrom = this.formData.budgetFrom
                const budgetTo = this.formData.budgetTo
                if (budgetFrom && budgetTo && Number(budgetFrom) > Number(budgetTo)) {
                    this.isBudgetInvalid = true
                    return true
                }
            },
            scrollToError() {
                this.$refs.content.scrollTop = 0
            },
            updateParameter(values, field) {
                this.formData = {
                    ...this.formData,
                    [field]: values,
                }

                // if (field === 'name' && this.$tours['startTour'].currentStep === 1) {
                //     this.$tours['startTour'].nextStep()
                // }
                //
                // if (field === 'keyWords' && this.$tours['startTour'].currentStep === 2) {
                //     this.$tours['startTour'].nextStep()
                // }
            },
            async createFilter() {
                if (this.$v.$invalid || this.compareBudget()) {
                    this.$v.$touch()
                    this.scrollToError()
                    return
                }

                const filterData = createFilterData(this.formData)
                filterData.profile = `/${this.profileId}`
                filterData.searchQuery = and(...generateTabFilters(filterData.searchCriteria))

                try {
                    await this.createFilterTab(filterData)
                    await this.getFilterTabs(this.profileId)
                    const filterTabByIndex = this.filterTabByIndex(3)
                    this.$router.push(`${purchasesPath}/${filterTabByIndex.id}`)
                    this.setCurrentTab(filterTabByIndex)
                    if (this.$tours['startTour'].currentStep === 5) {
                        this.$tours['startTour'].nextStep()
                    }
                    this.$modal.close()
                } catch (e) {
                    this.errorMessage = e.message
                    this.scrollToError()
                }

            },
            async editFilter() {
                if (this.$v.$invalid || this.compareBudget()) {
                    this.$v.$touch()
                    this.scrollToError()
                    return
                }

                const filterData = createFilterData(this.formData)
                filterData.searchQuery = and(...generateTabFilters(filterData.searchCriteria))

                try {
                    await this.editFilterTab({filterId: this.formData.id, filterData})

                    const tab = this.filterTabByParam('id', this.formData.id)

                    if (parseInt(this.$route.params.id) === this.formData.id) {
                        this.setCurrentTab(tab)
                        this.$modal.close()
                        return
                    }

                    this.$router.push(`${purchasesPath}/${this.formData.id}`)
                    this.setCurrentTab(tab)
                    this.$modal.close()
                } catch (e) {
                    this.errorMessage = e.message
                    this.scrollToError()
                }
            },
            onChangePurchaseType(value) {
                this.updateParameter(value, 'purchasesTypes')
            },
            onChangePlaceType(value) {
                this.updateParameter(value, 'placesTypes')
            },
            onChangeSectorType(value){
            this.updateParameter(value, 'sectorsTypes')
          },
        },
        created() {
            this.formData = this.componentProps.formData
        },
        mounted() {
            if (!this.$tours['startTour'].isRunning && this.hintsEnabled) {
                this.$tours['startTour'].start('1')
            }
            this.$refs['filterNameInput'].focus()
        }
    }
</script>

<style lang="less">
    @import '../../assets/less/variables.less';
    @import '../../assets/less/bootstrapGrid.less';
    @import '../../assets/less/commonStyles.less';

    .budget {
        position: relative;

        &::after {
            content: '-';
            position: absolute;
            right: -2px;
            top: 14px;
            z-index: 1;
            color: @bg-color;
        }
    }
</style>
