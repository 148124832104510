<template>
    <button
            type="button"
            @click="hidePurchase"
            title="Скрыть закупку"
            class="hide-purchase-btn"
            v-if="!hidden"
    >
        <ico name="icoVisibilityOff" size="24" color="#79919C"/>
    </button>
    <button
            type="button"
            @click="restorePurchase"
            title="Восстановить закупку"
            class="hide-purchase-btn"
            v-else
    >
        <ico name="icoVisibility" size="24" color="#79919C"/>
    </button>
</template>
<script>
    import Vue from 'vue'
    import {mapActions, mapGetters} from "vuex"
    import api from "@/application"
    import {getPurchasesUrl} from "@/utils/purchasesURL"
    import {mapColumnsFilters} from "@/utils/mapper"

    export default Vue.extend({
        name: "HiddenCellRendererFramework",
        data() {
            return {
                lotId: null,
                hiddenId: null,
                hidden: false
            }
        },
        computed: {
            ...mapGetters('filterTabs', ['currentTab']),
            ...mapGetters('profile', ['profileId']),
            ...mapGetters('columnsFilters', ['columnsFilters']),
            ...mapGetters('purchasesTableSettings', ['isActiveSearch', 'pagination']),
            ...mapGetters('columnsSort', ['columnSort']),
            ...mapGetters('purchases', ['page']),
        },
        methods: {
            ...mapActions('purchases', ['getPurchases']),
            ...mapActions('filterTabs', ['setZeroAllLots']),
            ...mapActions('purchasesScroll', ['setScroll']),
            ...mapActions('purchases', ['getPurchases']),
            async hidePurchase() {
                try {
                    this.setScroll(document.querySelector('div[ref="eBodyViewport"]').scrollTop)
                    await api.purchases.addLotToHidden({
                        profile: `/${this.profileId}`,
                        lotId: this.lotId
                    })
                    this.setZeroAllLots(this.currentTab)

                } catch (e) {
                    this.$toast.error(e.response.data.errorMessage)
                }

            },
            async restorePurchase() {
                try {
                    this.setScroll(document.querySelector('div[ref="eBodyViewport"]').scrollTop)
                    await api.purchases.removeLotFromHidden(this.hiddenId)
                    await this.getPurchases({
                        url: getPurchasesUrl(this.currentTab.filterType),
                        profileId: this.profileId,
                        customFilters: mapColumnsFilters(this.columnsFilters),
                        currentTab: this.currentTab,
                        isActiveSearch: this.isActiveSearch,
                        size: this.pagination.value,
                        sort: this.columnSort,
                        filterId: this.currentTab.id,
                        page: this.page.number
                    })

                } catch (e) {
                    this.$toast.error(e.response.data.errorMessage)
                }
            }
        },
        created() {
            this.lotId = this.params.value.lotId
            this.hiddenId = this.params.value.hiddenId
            this.hidden = this.params.value.hidden
        }
    })
</script>
<style scoped>
    button {
        display: block;
        margin: 0 auto;
        width: 24px;
        height: 24px;
    }
</style>