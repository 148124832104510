<template>
    <button
        class="toggle-column-btn"
        :class="{hidden: getColumnProperty(column.field, 'hide')}"
        type="button"
        @click="toggleColumnVisibility(column.field)"
    >
        {{ column.headerName }}
        <span><ico name="icoDragHandle" color="#CFD8DD"></ico></span>
    </button>
</template>

<script>
    import {eventBus} from "@/main"
    import {mapGetters} from 'vuex'
    export default {
        name: "ToggleColumnVisibilityBtn",
        props: ['column'],
        computed: {
            ...mapGetters('purchasesTableSettings', ['getColumnProperty']),
        },
        methods: {
            toggleColumnVisibility(columnName) {
                eventBus.$emit('toggleColumnVisibility', columnName)
            }
        }
    }
</script>

<style lang="less" scoped>
    @import '../../assets/less/variables.less';
    .toggle-column-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 2px;
        padding-left: 36px;
        width: 100%;
        text-align: left;
        background-image: url("../../assets/img/ico-checkbox-checked-grey.svg");
        background-repeat: no-repeat;
        background-position: left center;
        break-inside: avoid;

        &.hidden {
            background-image: url("../../assets/img/ico-checkbox-unchecked.svg");
        }
        span {
            margin-left: 8px;
            flex-shrink: 0;
            cursor: move;
        }
    }
</style>
