<template>
    <div class="modal-content">
        <div class="ico-wrapper">
            <ico name="icoHelp" color="#9fabb1fa" size="72"></ico>
        </div>
        <p v-html="componentProps.text"></p>
        <div class="form-footer">
            <button
                class="btn btn-danger"
                type="button"
                @click="remove"
            >
                Удалить
            </button>
            <button
                class="btn btn-transparent"
                type="button"
                @click="closeModal"
            >
                Отмена
            </button>
        </div>
    </div>
</template>
<script>
    export default {
        name: "ConfirmModal",
        props: ['componentProps'],
        methods: {
            closeModal() {
                this.$modal.close()
            },
            remove() {
                this.componentProps.callback()
                this.closeModal()
            }
        }
    }
</script>
<style lang="less" scoped>
    .ico-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 16px;
    }
    p {
        line-height: 1.5;
        text-align: center;
        color: #ffffff;
    }
    .form-footer {
        padding-top: 24px;
    }
</style>