<template>
  <span class="organization-cell-wrapper" ref="wrapper">
    <p v-if="!showTooltip"
       ref="organizationValue"
       :class="{blur: !authorities.includes('ROLE_SUBSCRIBED_USER')}"
       v-html="highlight()"
    >
    </p>
    <el-popover v-else
                popper-class="organization-tooltip"
                placement="top-start"
                trigger="hover">
    <p class="organization-value" v-html="highlight()"></p>

    <template slot="reference">
      <p class="text-value"
         ref="organization-value" v-html="highlight()"></p>
    </template>
  </el-popover>
  </span>

</template>

<script>
import Vue from 'vue'
import {mapGetters} from "vuex"
import {highlightSubstring} from './Highlights'

export default Vue.extend({
  name: "OrganizationCellRendererFramework",
  computed: {
    ...mapGetters('auth', ['authorities']),
    ...mapGetters('filterTabs', ['currentTab', 'filterTabByParam']),
  },
  data() {
    return {
      showTooltip: false,
      resizeObserver: null,
    }
  },
  mounted() {
    setTimeout(() => {
      this.setVisibleValue()

      if (this.$refs?.wrapper) {
        this.resizeObserver = new ResizeObserver(() => {
          this.setVisibleValue()
        })
        this.resizeObserver.observe(this.$refs.wrapper)
      }
    }, 1000)
  },
  methods: {
    highlight(){
      let arr
      if(this.currentTab.searchCriteria.regions || this.currentTab.searchCriteria.organizations){
        arr = [...(this.currentTab.searchCriteria.regions || []), ...(this.currentTab.searchCriteria.organizations || [])];
      }else{
        arr = []
      }

      return highlightSubstring(this.params.value, arr || [])
    },
    setVisibleValue() {
      const el = this.$refs.organizationValue
      const elParent = this.$refs.wrapper

      if (el && elParent) {
        this.showTooltip = (elParent.clientHeight < el.offsetHeight) ||
            (elParent.clientWidth < el.offsetWidth) ||
            (el.scrollHeight > el.clientHeight) ||
            (el.scrollWidth > el.clientWidth)
      }
    }
  }
})
</script>

<style lang="less">
.organization-cell-wrapper {
  display: block;
}

.organization-tooltip {
  padding: 4px 8px;
  font-family: Roboto, sans-serif;
  color: #F7F7F7;
  letter-spacing: 0.3px;
  background-color: rgba(121, 145, 156, 0.9);
  line-height: 16px;
  word-break: break-word;
  border: none;
  max-width: 300px;

  text-align: left;
  width: max-content !important;
  min-width: 40px!important;
  text-align: left;

  .popper__arrow {
    display: none;
  }

}

/*.blur {*/
/*    filter: blur(4px);*/
/*    -webkit-user-select: none;*/
/*    -moz-user-select: none;*/
/*    -ms-user-select: none;*/
/*    -o-user-select: none;*/
/*    user-select: none;*/

/*}*/
</style>