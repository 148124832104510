<template>
    <draggable
            v-model="tabs"
            class="draggable-list-container"
    >
        <draggable-filter-tab
                v-for="tab in tabs"
                :key="tab.id"
                :draggable-tab="tab"
                @onChangeTab="onChangeTab"
        />
    </draggable>
</template>

<script>
    import draggable from 'vuedraggable'
    import {mapActions, mapGetters} from "vuex"
    import DraggableFilterTab from "./DraggableFilterTab"

    export default {
        name: "DraggableFilterTabsList",
        components: {
            DraggableFilterTab,
            draggable,
        },
        data() {
            return {

            }
        },
        computed: {
            ...mapGetters('filterTabs', ['filterTabs']),
            ...mapGetters('profile', ['profileId']),
            tabs: {
                get() {
                    return this.filterTabs
                },
                set(newTabs) {
                    this.updateAllFilterTabs({newFilterTabs: newTabs, profileId: this.profileId})
                }
            },
        },
        methods: {
            ...mapActions('filterTabs', ['updateAllFilterTabs']),
            onChangeTab(tab) {
                this.$emit('onChangeTab', tab)
            }
        }
    }
</script>

<style scoped lang="less">
    @import '../../assets/less/variables.less';

    .draggable-list-container {
        overflow-y: auto;
        position: absolute;
        background-color: white;
        top: 100%;
        right: 0;
        width: 306px;
        max-height: 506px;
        padding: 8px;
        box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        z-index: 100;
    }

    &::-webkit-scrollbar {
        border-radius: @br;
        width: 8px;
        background-color: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: @br;
        width: 8px;
        background-color: fade(#000000, 24%);
        &:hover {
            background-color: fade(#000000, 30%);
        }
    }
</style>