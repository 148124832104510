<template>
    <div :class="['filter', {'full-width': isFullWidth}]">
        <p class="filter-text" @click="editFilter">
            {{filterItem.text}}
        </p>
        <button class="remove-btn" @click.stop="deleteFilter" type="button">
            <ico name="icoCancel" size="16" color="#79919C"/>
        </button>
    </div>
</template>

<script>
    export default {
        name: "SelectedFilterItem",
        props: ['filterItem', 'id', 'isFullWidth'],
        methods: {

            deleteFilter() {
                this.$emit('deleteFilter', this.id)
            },
            editFilter() {
                this.$emit('editFilter', this.id, this.filterItem.text)
            },
        }
    }
</script>

<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    .filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;
        padding: 0 4px 0 0;
        margin: 1px 1px;
        max-width: 116px;
        height: 24px;
        background-color: @bg-color-middle;
        cursor: pointer;
    }
    .remove-btn {
        height: 16px;
    }
    .filter-text {
        overflow: hidden;
        max-width: 84px;
        padding: 6px 4px 6px 12px;
        font-weight: normal;
        font-size: 12px;
        line-height: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .filter.full-width {
        max-width: none;
        .filter-text {
            max-width: none;
        }
    }

</style>
