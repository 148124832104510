<template>
    <button
            type="button"
            @click="toggleActive"
    >
        <span v-if="!isActive" key="ico-favorite">
            <ico name="icoStarBorder" color="#79919C"></ico>
        </span>
        <span v-else key="ico-favorite-filled">
            <ico name="icoStar" color="#FFB645"></ico>
        </span>
    </button>
</template>

<script>
    import Vue from 'vue'
    import api from "@/application"
    import {mapActions, mapGetters} from 'vuex'

    export default Vue.extend({
        name: "FavoritesCellRendererFramework",
        data() {
            return {
                isActive: false,
                favoriteId: null,
                lotId: null,
            }
        },
        computed: {
            ...mapGetters('profile', ['profileId']),
        },
        methods: {
            ...mapActions('purchases', ['setFavorite']),
            async toggleActive() {
                this.isActive = !this.isActive
                if (this.isActive) {
                    try {
                        const {data} = await api.purchases.createFavoriteLot({
                            profile: `/${this.profileId}`,
                            lotId: this.lotId
                        })
                        this.setFavorite({favoriteId: data.id, lotId: this.lotId, favorite: this.isActive})
                        this.favoriteId = data.id
                    } catch (e) {
                        this.$toast.error(e.response.data.errorMessage)
                    }
                } else {
                    try {
                        await api.purchases.removeFavoriteLot(this.favoriteId)
                        this.setFavorite({lotId: this.lotId, favorite: this.isActive})
                    } catch (e) {
                        this.$toast.error(e.response.data.errorMessage)
                    }
                }
            }
        },
        created() {
            this.isActive = this.params.value.favorite
            this.favoriteId = this.params.value.favoriteId
            this.lotId = this.params.value.lotId
        }
    })
</script>

<style scoped>
    button {
        display: block;
        margin: auto;
        width: 24px;
        height: 24px;
    }

    span {
        display: block;
        width: 24px;
        height: 24px;
    }
</style>
