<template>
    <div>
        <div :class="['filters-container', {active: !isSelectedValuesEmpty, vertical: isVertical}]">
            <div class="filters">
                <selected-filter-item
                    v-for="(item, id) in selectedValues"
                    :id="id"
                    :key="id"
                    :filter-item="item"
                    :isFullWidth="true"
                    @deleteFilter="deleteFilter"
                    @editFilter="editFilter"
                />
            </div>
            <div class="input-container">
                <input type="text"
                       class="filter-input form-control form-control-light"
                       :placeholder="placeholder"
                       v-model="currentValue"
                       @keyup.enter.exact="onEnterPressed"
                       @change="onChangeInput"
                       v-mask="inputmask"
                       @input="removeSpacesAndSymbols"
                />
                <button v-if="editableFilterId" @click.stop="cancelEdit" type="button">
                    <ico name="icoCancel" size="16" color="#79919C"/>
                </button>
            </div>
        </div>
        <p v-if="errorText" class="invalid-feedback">{{errorText}}</p>
    </div>
</template>

<script>
    import {uuid} from 'vue-uuid'
    import SelectedFilterItem from '@/components/common/SelectedFilterItem'
    import {isEmpty} from "lodash"

    export default {
        name: "MultipleInput",
        components: {
            SelectedFilterItem
        },
        props: ['isVertical', 'placeholder', 'inputmask', 'selectedValues'],
        data() {
            return {
                currentValue: '',
                editableFilterId: null,
                errorText: null
            }
        },
        computed: {
            isSelectedValuesEmpty() {
                return isEmpty(this.selectedValues)
            }
        },
        methods: {
            removeSpacesAndSymbols() {
                this.errorText = null
                if (!/[A-zА-я0-9ё]/.test(this.currentValue.charAt(0))) {
                    this.currentValue = ''
                }
            },
            validate(value) {
                if (!/^[A-zА-я0-9ё\s-*№]+$/.test(value)) {
                    this.errorText = 'Для ввода допустимы следующие символы: A-Z, a-z, А-Я, а-я, 0-9, пробел, -, *, №'
                    return true
                }
                if (!/^([A-zА-я0-9ё]+-?(\*(?=\s|$))?\s?)+$/.test(value)) {
                    this.errorText =
                        'После * запрещено вводить любые символы, кроме пробела. Недопустимо вводить более одного пробела, дефиса или * подряд'
                    return true
                }
            },
            cancelEdit() {
                this.errorText = null
                this.clearState()
            },
            onEnterPressed(event) {
                (this.currentValue !== '' && this.currentValue === event.target.value && !this.inputmask &&
                    !this.validate(event.target.value)) &&
                this.clearState()
            },
            onChangeInput(event) {
                if (event.target.value !== '') {
                    if (!this.inputmask && this.validate(event.target.value)) {
                        return
                    }

                    this.isInvalid = false
                    const id = this.editableFilterId ?? uuid.v4()

                    this.$emit('updateFilter', {
                        ...this.selectedValues,
                        [id]: {
                            text: event.target.value
                        }
                    })
                    this.clearState()
                }

            },
            deleteFilter(id) {
                // eslint-disable-next-line no-unused-vars
                const {[id]: removed, ...rest} = this.selectedValues

                this.$emit('updateFilter', rest)
                this.clearState()
            },
            editFilter(id, text) {
                this.editableFilterId = id
                this.currentValue = text
            },
            clearState() {
                this.currentValue = ''
                this.editableFilterId = null
            }

        }
    }
</script>

<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    ::-webkit-scrollbar {
        width: 2px;
        height: 4px;
    }

    .filters-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 3px;
        border: 2px solid @text-color-lighter;
        height: 44px;
        width: 100%;

        &.active {
            padding-left: 16px;
        }

        @media(max-height: 900px) {
            min-height: 40px;
        }

        @media(max-height: 720px) {
            min-height: 36px;
        }
    }

    .filters {
        overflow-y: hidden;
        overflow-x: auto;
        display: flex;
        padding-top: 4px;
        white-space: nowrap;
    }

    .input-container {
        flex-grow: 1;
        position: relative;
    }

    .filter-input {
        padding: 0 24px 0 16px;
        border: none;
        width: 100%;
        height: 40px;
        font-size: 15px;
        letter-spacing: 0.3px;
        background-color: transparent;
        .placeholder({
            color: @text-color-light;
        });

        &:focus {
            outline: none;
        }

        @media(max-height: 900px) {
            height: 36px;
        }

        @media(max-height: 720px) {
            height: 32px;
        }
    }

    .input-container button {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        padding-top: 4px;
        width: 20px;
    }

    .filters-container.vertical {
        flex-wrap: wrap;
        height: auto;
        min-height: 44px;

        @media(max-height: 900px) {
            min-height: 40px;
        }

        @media(max-height: 720px) {
            min-height: 36px;
        }

        &.active {
            padding-left: 0;
            padding-top: 8px;

            .filters {
                padding-top: 0;
                padding-left: 12px;
                padding-right: 12px;
            }
        }

        .filters {
            flex-wrap: wrap;
        }

        .input-container {
            width: 100%;
        }
    }
</style>
