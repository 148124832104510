<template>
    <div class="draggable-tab-container"
         :style="cssVars"
    >
        <div class="draggable-tab-container-handle">
            <span v-if="isCurrentTab" :key="draggableTab.id + '_' + 'draggable_tab' + '_' + 'active'" >
                <ico name="icoDragHandle" color="#ffffff" />
            </span>
            <span v-else :key="draggableTab.id + '_' + 'draggable_tab'">
                <ico name="icoDragHandle" color="#d8dee2" />
            </span>
        </div>
        <button
                class="draggable-tab-inner-container"
                @click.stop="onClickDraggableTab(draggableTab)"
                :disabled="isRequesting || (!authorities.includes('ROLE_SUBSCRIBED_USER') && draggableTab.filterType === 'GENERAL')"
                @mouseenter="crudMenuVisible = true"
                @mouseleave="crudMenuVisible = false"
        >
            <div
                    class="draggable-tab-inner-container-name"
            >
                {{draggableTab.name}}
            </div>
            <div class="draggable-tab-inner-container-crud-tab-counter">
                <div
                        v-if="crudMenuVisible && draggableTab.editable && authorities.includes('ROLE_SUBSCRIBED_USER')"
                        class="draggable-tab-inner-container-crud-tab-counter-crud-tab"
                >
                    <button
                            type="button"
                            @click.stop="onClickEditDraggableTab"
                    >
                        <ico name="icoEdit" size="20" color="#79919C"/>
                    </button>
                    <button
                            type="button"
                            @click.stop="openConfirmModal"
                    >
                        <ico name="icoDelete" size="20" color="#79919C"/>
                    </button>
                </div>
                <div
                        class="draggable-tab-inner-container-crud-tab-counter-counter"
                        v-if="draggableTab.newLots > 0"
                >
                    <div class="draggable-tab-inner-container-crud-tab-counter-counter-number">
                        + {{draggableTab.newLots}}
                    </div>
                </div>
            </div>
        </button>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex"
    import {ConfirmModalProperties, PurchasesTableEditCustomFilterModalProperties} from "@/utils/modalsProperties"
    import PurchasesTableCustomFilterModal
        from "@/components/purchasesTableCustomFilter/PurchasesTableCustomFilterModal"
    import {mapFromDbToForm} from "@/utils/mapper"
    import ConfirmModal from "@/components/common/ConfirmModal"
    import {purchasesPath} from "@/router/purchases"

    export default {
        name: "DraggableFilterTab",
        props: ['draggableTab'],
        data() {
            return {
                crudMenuVisible: false,
            }
        },
        computed: {
            ...mapGetters('filterTabs', ['filterTabByParam', 'filterTabs', 'currentTab']),
            ...mapGetters('profile', ['profileId']),
            ...mapGetters('auth', ['authorities']),
            ...mapGetters('purchases', ['isRequesting']),
            isCurrentTab() {
                return this.draggableTab.id === this.currentTab.id
            },
            cssVars() {
                const backgroundColor = this.draggableTab.id === this.currentTab.id ? '#cfd8dd' : 'white'
                const fontWeight = this.draggableTab.id === this.currentTab.id ? 500 : 200

                return {
                    '--background-color': backgroundColor,
                    '--font-weight': fontWeight,
                }
            }
        },
        methods: {
            ...mapActions('filterTabs', ['getFilterTabs', 'deleteFilterTab', 'setCurrentTab']),
            onClickDraggableTab(draggableTab) {
                this.$emit('onChangeTab', draggableTab)
            },
            onClickEditDraggableTab() {
                this.$modal.show(
                    PurchasesTableCustomFilterModal,
                    {
                        formData: mapFromDbToForm(this.draggableTab),
                        mode: 'Edit'
                    },
                    PurchasesTableEditCustomFilterModalProperties
                )

            },
            async onClickRemoveDraggableTab() {
                try {
                    await this.deleteFilterTab(this.draggableTab.id)
                    await this.getFilterTabs(this.profileId)

                    let tab = this.filterTabByParam('id', this.currentTab.id)

                    if (!tab) {
                        tab = this.filterTabByParam('filterType', 'ALL')
                        this.$router.push(`${purchasesPath}/${tab.id}`)
                        this.setCurrentTab(tab)
                        this.$emit('closeDraggableTabFiltersMenu')
                        return
                    }

                    this.$emit('closeDraggableTabFiltersMenu')
                } catch (e) {
                    this.$toast.error(e.message)
                }
            },
            openConfirmModal() {
                this.$modal.show(
                    ConfirmModal,
                    {
                        text: `Вы действительно хотите удалить фильтр <strong>${this.draggableTab.name}</strong>?`,
                        callback: this.onClickRemoveDraggableTab
                    },
                    ConfirmModalProperties
                )
            },
        },
    }
</script>

<style scoped lang="less">
    @import '../../assets/less/variables.less';

    .draggable-tab-container {
        display: flex;
        border-radius: 2px;
        height: 40px;
        background-color: var(--background-color);
        cursor: pointer;
        &-handle {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .draggable-tab-inner-container {
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 4px;
        margin-right: 4px;
        width: 100%;

        &-crud-tab-counter {
            display: flex;
            align-items: center;

            &-crud-tab {
                display: flex;
            }

            &-counter {
                display: flex;
                align-items: center;
                border-radius: 12px;
                margin-left: 5px;
                padding: 0 2px;
                height: 24px;
                max-width: 50px;
                font-size: 12px;
                color: @text-color-light;
                background-color: @bg-color-light;

                &-number {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

        }

        &-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: var(--font-weight);
        }

    }

</style>