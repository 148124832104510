<template>
    <div class="test-purchase-hint">
        <div
                v-html="proposalName"
                class="test-purchase-hint__proposal-name"
        ></div>
        <div class="test-purchase-hint__price">{{price}}</div>
        <div class="test-purchase-hint__number">{{number}}</div>
    </div>
</template>

<script>
    export default {
        name: "TestPurchaseHint",
        props: {
            proposalName: {
                type: String,
                required: true,
                default: '',
            },
            price: {
                type: String,
                required: true,
                default: '',
            },
            number: {
                type: String,
                required: true,
                default: '',
            },
        }
    }
</script>

<style scoped lang="less">
    .test-purchase-hint {
        display: flex;

        &__proposal-name {
            width: 60%;
        }

        &__number {
            width: 20%;
        }

        &__price {
            width: 20%;
        }
    }
</style>