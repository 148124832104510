<template>
    <div class="exclude-words-hint">
        <hint-body>
            <template v-slot:header>
                <div class="vue-tour-step__title">
                    Как пользоваться &laquo;исключениями&raquo;
                </div>
            </template>
            <template v-slot:content>
                <div class="exclude-words-hint__description">
                    Например, ваши ключевые слова:
                    <hint-label label="маски"/>
                    <hint-label label="масок"/>
                    <hint-label label="маска"/>
                    или
                    <hint-label label="мас*"/>
                    соответственно, сервис отображает: <span class="vue-tour-hint">маски медицинские</span>, <span class="vue-tour-hint">строительные</span>, <span class="vue-tour-hint">карнавальные</span>,
                    <span class="vue-tour-hint">кислородные</span>, <span class="vue-tour-hint">для триммера</span> и
                    прочие.
                </div>
                <div class="exclude-words-hint__description">
                    В этой ячейке вы можете внести определенные слова в исключения и сервис не будет отображать закупки,
                    в
                    которых присутствуют, указанные вами, слова-исключения.
                </div>
                <div class="exclude-words-hint__description">
                    Так, указав в этой ячейке:
                    <hint-label label="триммер*"/>
                    <hint-label label="строитель*"/>
                    <hint-label label="кислородн*"/>
                    система будет автоматически исключать из выдачи <span class="vue-tour-hint">маски для триммера</span>, <span class="vue-tour-hint">строительные маски</span>,
                    <span class="vue-tour-hint">маски кислородные</span>.
                    Но будет отображать все остальные закупки масок.
                </div>
                <div class="exclude-words-hint__description">
                    Чем больше слов-исключений - тем меньше &laquo;сорных&raquo; закупок в выдаче.
                </div>
            </template>
        </hint-body>
    </div>
</template>

<script>
    import HintLabel from "./HintLabel";
    import HintBody from "./HintBody";

    export default {
        name: "ExcludeWordsHint",
        components: {HintBody, HintLabel}
    }
</script>

<style scoped lang="less">

    .exclude-words-hint {

      width: 650px;

        &__description {
            padding: 2px 0;
        }
    }
</style>
