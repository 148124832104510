<template>
    <div class="hint-body">
        <div class="hint-body__header">
            <slot name="header" />
        </div>
        <div class="hint-body__content">
            <slot name="content" />
        </div>
        <div class="hint-body__footer">
            <div>
                При возникновении любых вопросов вы всегда можете нам позвонить: <span style="color: #FFB645;">+375 44 722 88 33</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HintBody"
    }
</script>

<style scoped lang="less">

    .hint-body {

        min-width: 610px;

        &__header {
            margin-bottom: 4px;
        }

        &__content {

        }

        &__footer {
            margin-top: 4px;
        }
    }

</style>