<template>
    <div class="region-words-hint">
        <hint-body>
            <template v-slot:header>
                <div class="vue-tour-step__title">
                    Как задать поиск по одному или нескольким регионам
                </div>
            </template>
            <template v-slot:content>
                <div class="hint-content__option">
                    <div class="hint-content__option__label">
                        <hint-label label="Минск"/>
                    </div>
                    <div class="hint-content__option__value">
                        Если вас интересует только <span class="vue-tour-hint">Минск</span>
                    </div>
                </div>
                <div class="hint-content__option">
                    <div class="hint-content__option__label">
                        <hint-label label="Минск*"/>
                    </div>
                    <div class="hint-content__option__value">
                        Если вас интересует <span class="vue-tour-hint">Минск</span>&nbsp;и&nbsp;<span class="vue-tour-hint">Минск</span>ая область
                    </div>
                </div>
                <div class="hint-content__option">
                    <div class="hint-content__option__label">
                        <hint-label label="Минск* обл*"/>
                        или
                        <hint-label label="Минская обл*"/>
                    </div>
                    <div class="hint-content__option__value">
                        Если вас интересует только <span class="vue-tour-hint">Минск</span>ая <span class="vue-tour-hint">обл</span>асть, не включая город Минск
                    </div>
                </div>
            </template>
        </hint-body>
    </div>
</template>

<script>
    import HintBody from "./HintBody";
    import HintLabel from "./HintLabel";

    export default {
        name: "RegionWordsHint",
        components: {HintLabel, HintBody}
    }
</script>

<style scoped lang="less">

    .region-words-hint {
        width: 650px;
    }

    .hint-content__option {
        display: flex;
        padding: 2px 0;

        &__label {
            width: 50%;
        }

        &__value {
            width: 50%
        }
    }
</style>
