<template>
  <span ref="wrapper" class="text-with-tooltip">
      <p v-if="!showTooltip"
         class="text-value"
         ref="textValue">{{ params.value }}</p>
  <el-popover v-else
              popper-class="text-tooltip"
              placement="top-start"
              trigger="hover">
    <p>{{ params.value }}</p>

    <template slot="reference">
      <p class="text-value"
         ref="textValue">{{ params.value }}</p>
    </template>
  </el-popover>
  </span>


</template>

<script>
import Vue from "vue"

export default Vue.extend({
  name: "TextWithTooltipRendererFramework",
  data() {
    return {
      showTooltip: false,
      resizeObserver: null
    }
  },
  mounted() {
    setTimeout(() => {
      this.setVisibleValue()

      if (this.$refs?.wrapper) {
        this.resizeObserver = new ResizeObserver(() => {
          this.setVisibleValue()
        })
        this.resizeObserver.observe(this.$refs.wrapper)
      }
    }, 1000)
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.$refs.wrapper)
      this.resizeObserver = null
    }
  },

  methods: {
    setVisibleValue() {
      const el = this.$refs.textValue
      const elParent = this.$refs.wrapper

      if (el && elParent) {

        const paddingLrEl = (parseInt(window.getComputedStyle(elParent).paddingLeft) +
            parseInt(getComputedStyle(elParent).paddingRight))

        this.showTooltip = (elParent.clientHeight < el.offsetHeight) ||
            ((elParent.clientWidth - paddingLrEl) < el.offsetWidth) ||
            (el.scrollHeight > el.clientHeight) ||
            (el.scrollWidth > el.clientWidth)
      }
    }
  }
})
</script>

<style lang="less">
.text-with-tooltip {
  display: block;
}
.text-tooltip {
  padding: 4px 8px;
  font-family: Roboto, sans-serif;
  text-align: left;
  color: #F7F7F7;
  letter-spacing: 0.3px;
  background-color: rgba(121, 145, 156, 0.9);
  line-height: 16px;
  word-break: break-word;
  border: none;
  max-width: 290px;

  width: max-content !important;
  min-width: 40px!important;

  .popper__arrow {
    display: none;
  }

}

.text-value {
  display: inline;
}
</style>