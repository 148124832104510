<template>
    <div class="hint-wrapper" v-click-outside="closeHint">
        <button
                class="hint-btn"
                :class="{active: hintIsVisible}"
                type="button"
                @click="hintIsVisible = !hintIsVisible"
        >
            <ico name="icoHelpOutline" size="20"></ico>
        </button>
        <div v-if="hintIsVisible" class="hint">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Hint",
        data() {
            return {
                hintIsVisible: false
            }
        },
        methods: {
            closeHint() {
                this.hintIsVisible = false
            }
        }
    }
</script>

<style lang="less">
    @import '../../assets/less/variables.less';

    .hint-wrapper {
        position: relative;
    }

    .hint-btn {
        display: block;

        svg {
            display: block;
            fill: @text-color-light;
        }

        &.active {
            svg {
                fill: @bg-color;
            }
        }
    }

    .hint {
        position: absolute;
        top: 100%;
        z-index: 10;
        border-radius: @br;
        padding: 8px;
        letter-spacing: 0.3px;
        color: #ffffff;
        background-color: fade(@text-color-light, 90%);;
    }
</style>
