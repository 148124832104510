<template>
    <div class="dropdown top"
         v-click-outside="closeDropdown">
        <button
                class="dropdown-btn"
                type="button"
                @click="isDropdownOpen = !isDropdownOpen"
        >
            <span class="dropdown-value">{{lineHeightsType.value}}</span>
            <ico name="icoArrowDropDown" color="#263239"></ico>
        </button>
        <div v-if="isDropdownOpen"
             ref="dropdown"
             class="dropdown-popup">
            <button v-for="(lht) in lineHeightsTypes"
                    :key="lht.id"
                    type="button"
                    :class="{active: lineHeightsType.id === lht.id}"
                    class="dropdown-popup-value"
                    @click.stop="changeRowHeight(lht)"
            >
                {{lht.value}}
            </button>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'

    export default {
        name: "ChangeRowsHeightMenu",
        data() {
            return {
                isDropdownOpen: false,
                selected: null,
            }
        },
        computed: {
            ...mapGetters('purchasesTableSettings', ['lineHeightsType']),
            ...mapGetters('enums', ['lineHeightsTypes']),
        },
        methods: {
            ...mapActions('enums', ['getLineHeightsTypes']),
            ...mapActions('purchasesTableSettings', ['updateLineHeightsType']),
            closeDropdown() {
                this.isDropdownOpen = false
            },
            async changeRowHeight(lineHeightsType) {
                try {
                    await this.updateLineHeightsType(lineHeightsType)
                    this.isDropdownOpen = false
                } catch (e) {
                    this.$toast.error(e.message)
                }

            }
        },

        created() {
            this.lineHeightsTypes && this.getLineHeightsTypes()
        }
    }
</script>

<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    /*.dropdown-popup-value {*/
    /*    font-size: 12px;*/
    /*}*/

    /*.dropdown-value {*/
    /*    font-size: 12px;*/
    /*}*/
</style>
