<template>
    <button
            type="button"
            @click.stop="openPurchasesTableSettings"
    >
        <ico name="icoSettings" />
        <span>Настройки таблицы</span>
    </button>
</template>

<script>

    export default {
        name: "PurchasesTableSettingsBtn",
        methods: {
            openPurchasesTableSettings() {
                this.$emit('openPurchasesTableSettings')
            }
        },
    }
</script>

<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        font-weight: 500;
        letter-spacing: 0.3px;
        color: @text-color-light;

        span {
            margin-left: 8px;
        }
    }
</style>
