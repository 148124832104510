<template>
    <div :style="cssVars">
        {{Math.max(0, this.params.value)}}
    </div>
</template>

<script>
    import Vue from 'vue'

    export default Vue.extend({
        name: "DaysLeftCellRendererFramework",
        computed: {
            cssVars() {
                const color = this.params.value < 0
                        ? "red"
                        : "green"

                return {
                    "--color": color
                }
            },
        }
    })
</script>

<style lang="less" scoped>
    div {
        color: var(--color);
    }
</style>