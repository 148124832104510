export const columnNames = {
    selected: '',
    favorites: '',
    hidden: '',
    proposalNumber: '№ закупки',
    proposalSource: 'Адрес сайта',
    proposalType: 'Вид закупки',
    status: 'Статус',
    proposalName: 'Название закупки',
    lotsNumber: 'Количество лотов в закупке',
    lot: 'Название лота',
    quantity: 'Количество',
    price: 'Бюджет лота',
    currency: 'Валюта',
    expirationDays: 'Дней до окончания подачи',
    organization: 'Наименование и адрес организации',
    startDate: 'Дата начала приема предложений',
    endDate: 'Дата и время окончания приема предложений',
    place: 'Место поставки',
    deliveryTime: 'Срок поставки',
    contacts: 'Контактное лицо',
    unp: 'УНП',
    source: 'Источник финансирования',
    paymentMethod: 'Способ расчетов',
    paymentForBroker: 'Размер платы оператору',
    codeOKRB: 'Код ОКРБ',
    documents: 'Документы',
    sector: 'Отрасль'
}
