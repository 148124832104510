<template>
    <div
            :class="['tab', {active: isActive, editable: tab.editable, open: menuIsVisible, 'with-new': tab.newLots > 0}]"
    >
        <button
                class="tab-btn"
                type="button"
                @click="onChangeTab(tab)"
                :disabled="isRequesting || (!authorities.includes('ROLE_SUBSCRIBED_USER') && tab.filterType === 'GENERAL')"
        >
            <span>{{tab.name}}</span>
        </button>

        <div v-if="tab.newLots" class="tab-counter">+ {{tab.newLots}}</div>

        <div
                v-if="tab.editable"
                class="tab-menu-wrapper"
                @focusout="closeMenu"
        >
            <button
                    class="tab-btn-more"
                    type="button"
                    @click.stop="menuIsVisible = !menuIsVisible"
                    :disabled="!authorities.includes('ROLE_SUBSCRIBED_USER')"
            >
                <span v-if="isActive || menuIsVisible" key="active">
                    <ico name="icoMoreVert" color="#263239"/>
                </span>
                <span v-else key="simple">
                    <ico name="icoMoreVert" color="#79919C"/>
                </span>
            </button>
            <TabMenu v-if="menuIsVisible && authorities.includes('ROLE_SUBSCRIBED_USER')"
                     :filter-tab="tab"
                     @closeMenu="closeMenu"
            />
        </div>

        <!-- <p v-if="!menuIsVisible" class="tab-tooltip">
            {{getTooltip}}
        </p> -->

    </div>
</template>

<script>
    import TabMenu from '@/components/common/TabMenu'
    import {mapGetters} from "vuex"

    export default {
        name: "Tab",
        data() {
            return {
                menuIsVisible: false
            }
        },
        props: ['isActive', 'tab'],
        components: {
            TabMenu
        },
        computed: {
            ...mapGetters('auth', ['authorities']),
            ...mapGetters('purchases', ['isRequesting']),
            getTooltip() {

                return (this.authorities.includes('ROLE_SUBSCRIBED_USER') || this.tab.filterType !== 'GENERAL') ?
                    this.tab.name :
                    'Настраиваемые фильтры доступны только при наличии платной подписки'
            }
        },
        methods: {
            closeMenu() {
                setTimeout(() => {
                    this.menuIsVisible = false
                }, 200)
            },
            onChangeTab(tab) {
                this.$emit('onChangeTab', tab)
            }
        }
    }
</script>

<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    .tab {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: @br;
        padding: 0 8px;
        transition: background-color 0.4s linear;
        &.editable {
            padding-right: 24px;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -1px;
            z-index: 2;
            height: 2px;
            background-color: @text-color-light;
            opacity: 0;
            transition: @trans;
        }

        &-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 8px;
            height: 47px;

            span {
                overflow: hidden;
                max-width: 104px;
                font-weight: 500;
                font-size: 13px;
                letter-spacing: 0.25px;
                color: @text-color-light;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        &-menu-wrapper {
            visibility: hidden;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 2;
            display: flex;
            align-items: center;
            opacity: 0;
        }

        &-btn-more {
            padding-top: 4px;
        }

        &-counter {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            padding: 0 4px;
            height: 24px;
            font-size: 12px;
            white-space: nowrap;
            color: @text-color-light;
            background-color: @bg-color-light;
        }

        &.active {
            .tab-btn span {
                color: @text-color;
            }

            &::after {
                opacity: 1;
            }
        }

        &:hover {
            background-color: #F8F9F9;

            .tab-tooltip {
                visibility: visible;
                opacity: 1;
            }
        }

        &.editable:hover,
        &.editable.open {

            .tab-menu-wrapper {
                visibility: visible;
                opacity: 1;
            }
        }

        &.editable.with-new:hover {

            .tab-menu-wrapper {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .tab-tooltip {
        visibility: hidden;
        position: absolute;
        left: 0;
        top: calc(100% + 2px);
        z-index: 10;
        border-radius: @br;
        padding: 4px 8px;
        max-width: 300px;
        font-size: 13px;
        letter-spacing: 0.3px;
        color: #ffffff;
        background-color: fade(@text-color-light, 90%);
        opacity: 0;
        transition: opacity 0.4s linear;
    }
</style>
