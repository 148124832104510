<template>
    <span>{{this.params.value}}</span>
</template>

<script>
    import Vue from 'vue'

    export default Vue.extend({
        name: "SpanCellRendererFramework",
    })
</script>

<style scoped>

</style>