<template>
    <div class="tab-menu">
        <button
                class="tab-menu-btn"
                type="button"
                @click="edit"
        >
            <ico name="icoEdit" color="#79919C"/>
            <span>Редактировать</span>
        </button>
        <button
                class="tab-menu-btn"
                type="button"
                @click="openConfirmModal"
        >
            <ico name="icoDelete" color="#79919C"/>
            <span>Удалить</span>
        </button>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex"
    import {ConfirmModalProperties, PurchasesTableEditCustomFilterModalProperties} from "@/utils/modalsProperties"
    import PurchasesTableCustomFilterModal
        from "@/components/purchasesTableCustomFilter/PurchasesTableCustomFilterModal"
    import {mapFromDbToForm} from "@/utils/mapper"
    import ConfirmModal from "@/components/common/ConfirmModal"
    import {purchasesPath} from "@/router/purchases"

    export default {
        name: "TabMenu",
        props: ['filterTab'],
        computed: {
            ...mapGetters('filterTabs', ['filterTabByParam', 'filterTabs', 'currentTab']),
            ...mapGetters('profile', ['profileId']),
        },
        methods: {
            ...mapActions('filterTabs', ['getFilterTabs', 'deleteFilterTab', 'setCurrentTab']),
            edit() {
                this.$modal.show(
                    PurchasesTableCustomFilterModal,
                    {
                        formData: mapFromDbToForm(this.filterTab),
                        mode: 'Edit'
                    },
                    PurchasesTableEditCustomFilterModalProperties
                )
                this.$emit('closeMenu')
            },
            openConfirmModal() {
                this.$modal.show(
                    ConfirmModal,
                    {
                        text: `Вы действительно хотите удалить фильтр <strong>&laquo;${this.filterTab.name}&raquo;</strong> ?`,
                        callback: this.remove
                    },
                    ConfirmModalProperties
                )
            },
            async remove() {
                try {
                    await this.deleteFilterTab(this.filterTab.id)
                    await this.getFilterTabs(this.profileId)

                    let tab = this.filterTabByParam('id', this.currentTab.id)
                    if (!tab) {
                        tab = this.filterTabByParam('filterType', 'ALL')
                        this.$router.push(`${purchasesPath}/${tab.id}`)
                        this.setCurrentTab(tab)
                        this.$emit('closeMenu')
                        return
                    }
                    this.$emit('closeMenu')
                } catch (e) {
                    this.$toast.error(e.message)
                }

            }
        }
    }
</script>

<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    .tab-menu {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 15;
        border-radius: @br;
        padding: 8px 0;
        min-width: 192px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        transform: translateX(50%);

        &-btn {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 8px 16px;
            width: 100%;

            span {
                padding-left: 16px;
                letter-spacing: 0.3px;
                color: @bg-color;
            }

            &:hover {
                background-color: @bg-color-light;
            }
        }
    }
</style>
