<template>
    <div class="vue-tour-actions-container">
        <template v-if="!isFirstStep && enabledButtons.buttonPrevious">
            <div>
                <button
                        class="vue-tour-actions-container__prev-button vue-tour-actions-button button-transparent"
                        @click="previousStep"
                >
                <span style="height: 20px">
                    <ico name="icoChevronLeft" size="20" color="#79919C"/>
                </span>
                    <span>
                    Предыдущая
                </span>
                </button>
            </div>
        </template>

        <template v-else>
            <div></div>
        </template>

        <template v-if="!isLastStep && enabledButtons.buttonNext">
            <div>
                <button
                        class="vue-tour-actions-container__next-button vue-tour-actions-button"
                        @click="nextStep"
                >
                    Следующая
                </button>
            </div>
        </template>

        <template v-if="isLastStep">
            <button
                    class="vue-tour-actions-container__finish-button vue-tour-actions-button"
                    @click="finishStep"
            >
                Финиш
            </button>
        </template>
    </div>
</template>

<script>
    export default {
        name: "VueTourActions",
        props: {
            previousStep: {
                type: Function,
                required: false,
                default: () => {
                },
            },
            nextStep: {
                type: Function,
                required: false,
                default: () => {
                },
            },
            finishStep: {
                type: Function,
                required: false,
                default: () => {
                },
            },
            isLastStep: {
                type: Boolean,
                required: true,
            },
            isFirstStep: {
                type: Boolean,
                required: true,
            },
            enabledButtons: {
                type: Object,
                required: true,
                default: function () {
                    return {}
                },
            }
        },
    }
</script>

<style scoped lang="less">
    @import "../../assets/less/variables";

    .vue-tour-actions-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 0;

        &__prev-button {
            color: @text-color-light;
            padding: 0;

            span:last-child {
                margin-left: 10px;
            }
        }

        &__next-button {
            height: 30px;
            background-color: @text-color-light;
        }

        &__finish-button {
            height: 30px;
            background-color: @text-color-light;
        }
    }

</style>