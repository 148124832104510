<template>
    <div class="container">
        <button
                class="toggle-btn"
                :class="{active: !isDisabled && isActiveSearch}"
                type="button"
                @click="toggleIsActive"
                :disabled="isDisabled"
                :title="isDisabled ? `Сортировка по активности недоступна для вкладки «${currentTab.name}»` : ''"
                data-v-step="toggle-active-purchases"
        >
            <span></span>
            <span>Показать только активные закупки</span>
        </button>
        <Hint>
            <div class="hint-content" style="min-width: 300px">
                <p>
                    При отключении этой опции, в таблице будут так же отобра- жаться истекшие, удалённые или приостановленные закупки.
                </p>
            </div>
        </Hint>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import Hint from '../common/Hint'

    export default {
        name: "ToggleActivePurchasesBtn",
        computed: {
            ...mapGetters('purchasesTableSettings', ['isActiveSearch']),
            ...mapGetters('filterTabs', ['currentTab']),
            isDisabled() {
                return this.currentTab.filterType === 'FAVORITES' || this.currentTab.filterType === 'HIDDEN'
            }
        },
        methods: {
            ...mapActions('purchasesTableSettings', ['updateIsActiveSearch']),
            toggleIsActive() {
                try {
                    this.updateIsActiveSearch({isActiveSearch: !this.isActiveSearch, routeId: this.$route.params.id, routeQuery: this.$route.query})
                } catch (e) {
                    this.$toast.error(e.message)
                }

            },
        },
        components: {
            Hint
        }

    }
</script>

<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    .container{
        display: flex;
        gap: 7px;
    }
    .hint-wrapper{
        margin-top: 3px;
    }
    .hint-content{
       width: 100px;
       p{
        display: block;
        width: 250px
       }
    }
    p {
        letter-spacing: 0.3px;
        color: #ffffff;
    }

    .toggle-btn {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 16px;

        span {
            &:first-child {
                position: relative;
                display: block;
                margin-right: 16px;
                border-radius: 12px;
                width: 48px;
                height: 24px;
                background-color: @bg-color-middle;
                transition: @trans;

                &::before {
                    content: '';
                    position: absolute;
                    left: 3px;
                    top: 2px;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    background-color: #ffffff;
                    transition: @trans;
                }
            }
        }

        &.active {
            span:first-child {
                background-color: @bg-color-dark;

                &::before {
                    left: 25px;
                }
            }
        }
    }
</style>
