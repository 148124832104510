import FavoritesCellRendererFramework from '@/components/purchaseTable/cellRenderers/FavoritesCellRendererFramework'
import DocumentsArrayCellRendererFramework from "@/components/purchaseTable/cellRenderers/DocumentsArrayCellRendererFramework"
import LotNameWithLinkCellRendererFramework from "@/components/purchaseTable/cellRenderers/LotNameWithLinkCellRendererFramework"
import DaysLeftCellRendererFramework from "../../components/purchaseTable/cellRenderers/DaysLeftCellRendererFramework"
import ProposalNameCellRendererFramework from "@/components/purchaseTable/cellRenderers/ProposalNameCellRendererFramework"
import OrganizationCellRendererFramework from "@/components/purchaseTable/cellRenderers/OrganizationCellRendererFramework"
import HiddenCellRendererFramework from "@/components/purchaseTable/cellRenderers/HiddenCellRendererFramework"
import ProposalNumberWithLinkCellRendererFramework
    from "../../components/purchaseTable/cellRenderers/ProposalNumberWithLinkCellRendererFramework";
import {columnNames} from '@/utils/agGreed/columnNames'
import {equaldate} from "@/utils/rsql"
import ProposalSourceCellRendererFramework
    from "../../components/purchaseTable/cellRenderers/ProposalSourceCellRendererFramework";
import TextWithTooltipRendererFramework
    from "../../components/purchaseTable/cellRenderers/TextWithTooltipRendererFramework";
export const agGridColumnsDefs = {
    // selected: {
    //     headerName: columnNames.selected,
    //     filter: false,
    //     floatingFilter: false,
    //     resizable: false,
    //     lockPosition: true,
    //     headerCheckboxSelection: true,
    //     checkboxSelection: true,
    //     headerClass: 'checkbox-cell',
    //     cellClass: 'checkbox-cell',
    //     minWidth: 40,
    // },
    favorites: {
        headerName: columnNames.favorites,
        filter: false,
        floatingFilter: false,
        resizable: false,
        lockPosition: true,
        headerClass: 'favorites-cell',
        cellClass: 'favorites-cell',
        cellRendererFramework: FavoritesCellRendererFramework,
        minWidth: 26,
    },
    hidden: {
        headerName: columnNames.hidden,
        cellRendererFramework: HiddenCellRendererFramework,
        filter: false,
        floatingFilter: false,
        resizable: false,
        lockPosition: true,
        headerClass: 'hidden-cell',
        cellClass: 'hidden-cell',
        minWidth: 32,
    },
    proposalNumber: {
        headerName: columnNames.proposalNumber,
        headerTooltip: columnNames.proposalNumber,
        filterPath: 'proposal.number',
        sortingPath: 'proposal.number',
        cellRendererFramework: ProposalNumberWithLinkCellRendererFramework,
        headerComponentParams: {
            customSort: true
        },
    },
    proposalSource: {
        headerName: columnNames.proposalSource,
        headerTooltip: columnNames.proposalSource,
        cellRendererFramework: ProposalSourceCellRendererFramework,
        filterPath: 'proposal.source.value',
    },
    proposalType: {
        headerName: columnNames.proposalType,
        headerTooltip: columnNames.proposalType,
        filterPath: 'proposal.procedureType.value',
        cellRendererFramework: TextWithTooltipRendererFramework,
    },
    status: {
        headerName: columnNames.status,
        headerTooltip: columnNames.status,
        filterPath: 'status',
        cellRendererFramework: TextWithTooltipRendererFramework,
    },
    proposalName: {
        headerName: columnNames.proposalName,
        headerTooltip: columnNames.proposalName,
        filterPath: 'proposal.procedureName',
        cellRendererFramework: ProposalNameCellRendererFramework,
    },
    lotsNumber: {
        headerName: columnNames.lotsNumber,
        headerTooltip: columnNames.lotsNumber,
        // tooltipValueGetter: (params) => {
        //     return params.value
        // },
        filterPath: 'proposal.numberLots',
        sortingPath: 'proposal.numberLots',
        headerComponentParams: {
            customSort: true
        },
        floatingFilterComponentParams: {
            type: 'number'
        },
        cellRendererFramework: TextWithTooltipRendererFramework,
    },
    lot: {
        headerName: columnNames.lot,
        headerTooltip: columnNames.lot,
        cellRendererFramework: LotNameWithLinkCellRendererFramework,
        filterPath: 'name'
    },
    quantity: {
        headerName: columnNames.quantity,
        headerTooltip: columnNames.quantity,
        filterPath: 'quantity',
        cellRendererFramework: TextWithTooltipRendererFramework,
    },
    price: {
        headerName: columnNames.price,
        headerTooltip: columnNames.price,
        filterPath: 'price',
        sortingPath: 'price',
        headerComponentParams: {
            customSort: true
        },
        floatingFilterComponentParams: {
            type: 'decimal'
        },
        cellRendererFramework: TextWithTooltipRendererFramework,
    },
    currency: {
        headerName: columnNames.currency,
        headerTooltip: columnNames.currency,
        filterPath: 'currency',
        minWidth: 50,
        cellRendererFramework: TextWithTooltipRendererFramework,
    },
    expirationDays: {
        headerName: columnNames.expirationDays,
        headerTooltip: columnNames.expirationDays,
        cellRendererFramework: DaysLeftCellRendererFramework,
        filterPath: 'proposal.endDate',
        sortingPath: 'proposal.endDate',
        operation: equaldate,
        headerComponentParams: {
            customSort: true
        },
        floatingFilterComponentParams: {
            type: 'number'
        },
        minWidth:50
    },
    organization: {
        headerName: columnNames.organization,
        headerTooltip: columnNames.organization,
        filterPath: 'proposal.organization',
        cellRendererFramework: OrganizationCellRendererFramework,
    },
    startDate: {
        headerName: columnNames.startDate,
        headerTooltip: columnNames.startDate,
        filterPath: 'proposal.startDate',
        sortingPath: 'proposal.startDate',
        headerComponentParams: {
            customSort: true
        },
        floatingFilterComponentParams: {
            suppressFilterButton: true,
            type: 'date'
        },
        minWidth: 100,
        cellRendererFramework: TextWithTooltipRendererFramework,
    },
    endDate: {
        headerName: columnNames.endDate,
        headerTooltip: columnNames.endDate,
        filterPath: 'proposal.endDate',
        sortingPath: 'proposal.endDate',
        operation: equaldate,
        headerComponentParams: {
            customSort: true
        },
        floatingFilterComponentParams: {
            suppressFilterButton: true,
            type: 'date'
        },
        minWidth: 130,
        cellRendererFramework: TextWithTooltipRendererFramework,
    },
    place: {
        headerName: columnNames.place,
        headerTooltip: columnNames.place,
        filterPath: 'place',
        cellRendererFramework: TextWithTooltipRendererFramework,
    },
    deliveryTime: {
        headerName: columnNames.deliveryTime,
        headerTooltip: columnNames.deliveryTime,
        filterPath: 'deliveryTime',
        cellRendererFramework: TextWithTooltipRendererFramework,
    },
    contacts: {
        headerName: columnNames.contacts,
        headerTooltip: columnNames.contacts,
        filterPath: 'proposal.contact',
        cellRendererFramework: TextWithTooltipRendererFramework,
    },
    unp: {
        headerName: columnNames.unp,
        headerTooltip: columnNames.unp,
        filterPath: 'proposal.UNP',
        cellRendererFramework: TextWithTooltipRendererFramework,
    },
    source: {
        headerName: columnNames.source,
        headerTooltip: columnNames.source,
        filterPath: 'source',
        cellRendererFramework: TextWithTooltipRendererFramework,
    },
    paymentMethod: {
        headerName: columnNames.paymentMethod,
        headerTooltip: columnNames.paymentMethod,
        filterPath: 'paymentMethod',
        cellRendererFramework: TextWithTooltipRendererFramework,
    },
    paymentForBroker: {
        headerName: columnNames.paymentForBroker,
        headerTooltip: columnNames.paymentForBroker,
        filterPath: 'proposal.paymentForBroker',
        cellRendererFramework: TextWithTooltipRendererFramework,
    },
    codeOKRB: {
        headerName: columnNames.codeOKRB,
        headerTooltip: columnNames.codeOKRB,
        filterPath: 'codeOKRB',
        cellRendererFramework: TextWithTooltipRendererFramework,
    },
    documents: {
        headerName: columnNames.documents,
        headerTooltip: columnNames.documents,
        cellRendererFramework: DocumentsArrayCellRendererFramework,
        filterPath: 'proposal.docs.name'
    },
    sector: {
        headerName: columnNames.sector,
        headerTooltip: columnNames.sector,
        filterPath: 'proposal.sector.value',
        cellRendererFramework: TextWithTooltipRendererFramework,
    }
}
