<template>
  <span ref="wrapper" class="lot-name-wrapper">
    <template v-if="!showTooltip">
          <a v-if="authorities.includes('ROLE_SUBSCRIBED_USER')"
             :href="linkForLot"
             ref="nameLink"
             target="_blank"
             v-html ="this.highlight()"
          >
      </a>
      <p v-else ref="nameLink" v-html = 'this.highlight()'></p>
    </template>

    <el-popover v-else
                popper-class="proposal-lot-tooltip"
                placement="top-start"
                trigger="hover">
            <p class="tooltip-value" v-html = 'this.highlight()'></p>
    <template v-if="isBeloil">
      <div class="tooltip__link">Участие в данной закупке осуществляется на площадке <br>
        <a href="https://sapsrm.beloil.by" target="_blank">https://sapsrm.beloil.by</a>
      </div>
      <div class="tooltip__link">Инструкция для регистрации: <br>
        <a href="https://www.youtube.com/watch?v=w-7lsm1-csY"
           target="_blank">https://www.youtube.com</a>
      </div>
    </template>

    <template slot="reference">
      <a
          v-if="authorities.includes('ROLE_SUBSCRIBED_USER')"
          :href="linkForLot"
          target="_blank"
          ref="nameLink"
          v-html = 'this.highlight()'>
      </a>
      <p v-else ref="nameLink" v-html = 'this.highlight()'></p>
    </template>
  </el-popover>
  </span>

</template>

<script>
import Vue from 'vue'
import {mapGetters} from "vuex"
import { highlightSubstring } from './Highlights'

export default Vue.extend({
  name: "LotNameWithLinkCellRendererFramework",
  data() {
    return {
      showTooltip: false,
      resizeObserver: null
    }
  },
  computed: {
    ...mapGetters('auth', ['authorities']),
    ...mapGetters('filterTabs', ['currentTab', 'filterTabByParam']),

    /*
    * Due to this links lead to the source page, but not
    *  to the exact proposals the following changes were
    *  made to make links unique from the perspective of our platform.
    */
    linkForLot() {
      const newUrl = new URL(this.params.value.url)

      if (this.isBeloil || this.isMinsktrans || this.isBelaruskali)
        newUrl.searchParams.append('number', this.params.data.proposalNumber.proposalNumber)

      return newUrl.href
    },
    isBeloil() {
      return this.params.data.proposalSource === "https://sapsrm.beloil.by"
    },
    isMinsktrans() {
      return this.params.data.proposalSource === "https://zakupki.minsktrans.by"
    },
    isBelaruskali() {
      return this.params.data.proposalSource === "https://belaruskali.by"
    }
  },
  mounted() {
    setTimeout(() => {
      this.setValueForTooltip()

      if (this.$refs?.wrapper) {
        this.resizeObserver = new ResizeObserver(() => {
          this.setValueForTooltip()
        })
        this.resizeObserver.observe(this.$refs.wrapper)
      }
    }, 1000)
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.$refs.wrapper)
      this.resizeObserver = null
    }
  },
  methods: {
    highlight(){
      return highlightSubstring(this.params.value.lotName, this.currentTab.searchCriteria.keyWords)
    },

    setValueForTooltip() {
      const el = this.$refs.nameLink
      const elParent = this.$refs.wrapper

      if (el && elParent) {

        const paddingLrEl = (parseInt(window.getComputedStyle(elParent).paddingLeft) +
            parseInt(getComputedStyle(elParent).paddingRight))

        this.showTooltip = (elParent.clientHeight < el.offsetHeight) ||
            ((elParent.clientWidth - paddingLrEl) < el.offsetWidth) ||
            (el.scrollHeight > el.clientHeight) ||
            (el.scrollWidth > el.clientWidth) || this.isBeloil
      }
    }
  }
})
</script>

<style lang="less">
.lot-name-wrapper {
  display: block;
}

.proposal-lot-tooltip {
  padding: 4px 8px;
  font-family: Roboto, sans-serif;
  color: #F7F7F7;
  letter-spacing: 0.3px;
  background-color: rgba(121, 145, 156, 0.9);
  line-height: 16px;
  word-break: break-word;
  border: none;
  max-width: 290px;

  width: max-content !important;
  min-width: 40px !important;
  text-align: left;

  .popper__arrow {
    display: none;
  }

  .tooltip__link {
    color: #FFFFFF;
    margin-top: 6px;

    padding-top: 5px;
    border-top: 1px solid #9fadb5;

    a {
      color: #F7F7F7;
    }

    &:nth-child(3) {
      padding-top: 0;
      margin-top: 6px;
      border-top: none;
    }
  }
}
</style>