<template>
    <div class="modal-content">
        <div class="form-in-scroll">
            <p>В данной закупке присутствуют другие лоты, которые могут быть Вам интересны:</p>
            <ul>
                <li
                        v-for="(lot, index) in lots"
                        :key="'index_' + index"
                >
                    {{lot}}
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    import api from "@/application"

    export default {
        name: "OtherLotsModal",
        props: ['componentProps'],
        data() {
            return {
                lots: []
            }
        },
        async created() {
            try {
                const {data} = await api.purchases.getAnotherLots(this.componentProps.lotId)
                this.lots = data
            } catch (e) {
                this.$toast.error(e.response.data.errorMessage)
            }
        }
    }
</script>
<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    ul {
        margin-top: 24px;

        li {
            position: relative;
            margin-bottom: 8px;
            padding-left: 12px;

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 5px;
                border-radius: 50%;
                width: 6px;
                height: 6px;
                background-color: @text-color-light;
            }
        }
    }
</style>