<template>
    <div class="purchases">
        <div class="section-header">
            <TabList
                    @onChangeTab="onChangeTab"
            />
            <div class="dropdown-btn-container">
                <div
                        class="draggable-list-btn-container"
                        v-click-outside="closeDraggableTabFiltersMenu"
                >
                    <draggable-filter-tabs-list
                            v-if="isDraggableTabFiltersMenuOpen"
                            @closeDraggableTabFiltersMenu="closeDraggableTabFiltersMenu"
                            @onChangeTab="onChangeTab"
                    />
                    <draggable-filter-tabs-list-btn
                            :is-draggable-tab-filters-menu-open="isDraggableTabFiltersMenuOpen"
                            @onClickDraggableFilterTabsBtn="onClickDraggableFilterTabsBtn"
                    />
                </div>
                <PurchasesTableCustomFilterBtn data-v-step="create-filter-modal-button"
                                               @clickBtn="openCreateFilterModalWithHint"/>
            </div>
        </div>
        <PurchasesTable
                :purchases="purchases"
                :is-requesting="isRequesting"
        />
        <div class="purchases-footer">
            <Pagination
                    :page="page"
                    :links="links"
                    @onChangePagination="onChangePagination"
                    @onChangePage="onChangePage"
            />
            <div class="container">
                <div class="userInfo"
                    :title="shortNameOrganization">
                    <p v-if="organizationId">{{shortNameOrganization}} </p>
                    <p>{{userEmail}}</p>
                </div>
            <PurchasesTableSettingsBtn @openPurchasesTableSettings="openPurchasesTableSettingsWithHint"
                                       data-v-step="table-settings-button"/>
            </div>
        </div>
        <transition name="table-settings-menu">
            <PurchasesTableSettings
                    v-if="isSettingsOpen"
                    @closePurchasesTableSettings="closePurchasesTableSettingsWithHint"
            />
        </transition>
        <vue-tour
                tour-name="startTour"
                :steps="startSteps"
                :callbacks="{onFinish, onSkip}"
                :options="vueTourOptions"
        />
    </div>
</template>

<script>
    import Vue from 'vue'
    import {eventBus} from "@/main"
    import {mapActions, mapGetters} from 'vuex'
    import PurchasesTable from '@/components/purchaseTable/PurchasesTable'
    import PurchasesTableSettingsBtn from '@/components/purchasesTableSettings/PurchasesTableSettingsBtn'
    import PurchasesTableSettings from '@/components/purchasesTableSettings/PurchasesTableSettings'
    import PurchasesTableCustomFilterBtn from '@/components/purchasesTableCustomFilter/PurchasesTableCustomFilterBtn'
    import Pagination from "@/components/common/Pagination"
    import {mapColumnsFilters} from "@/utils/mapper"
    import DraggableFilterTabsList from "@/components/draggableFilterTabs/DraggableFilterTabsList"
    import DraggableFilterTabsListBtn from "@/components/draggableFilterTabs/DraggableFilterTabsListBtn"
    import TabList from "@/components/common/TabList"
    import {getPurchasesUrl} from "@/utils/purchasesURL"
    import api from "@/application"
    import {purchasesPath} from "@/router/purchases"
    import {isEmpty} from "lodash"
    import PurchasesTableCustomFilterModal
        from "@/components/purchasesTableCustomFilter/PurchasesTableCustomFilterModal"
    import {PurchasesTableCustomFilterModalProperties} from "@/utils/modalsProperties"
    import VueTour from "../components/vueTour/VueTour";
    import KeyWordsHint from "../components/hints/KeyWordsHint";
    import ExcludeWordsHint from "../components/hints/ExcludeWordsHint";
    import RegionWordsHint from "../components/hints/RegionWordsHint";

    export default {
        name: "PurchasesMonitoring",
        data() {
            return {
                isSettingsOpen: false,
                isDraggableTabFiltersMenuOpen: false,
            }
        },
        components: {
            VueTour,
            TabList,
            Pagination,
            PurchasesTable,
            PurchasesTableSettings,
            DraggableFilterTabsList,
            PurchasesTableSettingsBtn,
            DraggableFilterTabsListBtn,
            PurchasesTableCustomFilterBtn
        },
        computed: {
            ...mapGetters('purchases', ['purchases', 'page', 'links', 'isRequesting']),
            ...mapGetters('purchasesTableSettings', ['pagination', 'isActiveSearch']),
            ...mapGetters('filterTabs', ['currentTab', 'filterTabByParam']),
            ...mapGetters('columnsFilters', ['columnsFilters']),
            ...mapGetters('columnsSort', ['columnSort']),
            ...mapGetters('profile', ['profileId', 'hintsEnabled']),
            ...mapGetters('impersonalization', ['mindControlledUser']),
            ...mapGetters('auth', ['authorities', 'email']),
            ...mapGetters('organization', ['shortNameOrganization', 'organizationId']),

            userEmail() {
                return this.mindControlledUser ?? this.email
            },

            startSteps: function () {

                const keywordsHintComponent = new Vue({...KeyWordsHint}).$mount('#purchase-key-words-hint')

                const excludeWordsHintComponent = new Vue({...ExcludeWordsHint}).$mount('#purchase-exclude-words-hint')

                const regionWordsHintComponent = new Vue({...RegionWordsHint}).$mount('#purchase-region-words-hint')
                return [
                    {
                        target: '[data-v-step="create-filter-modal-button"]',
                        content: '<div class="vue-tour-step"><div class="vue-tour-step__title">Для начала работы в системе давайте создадим ваш поисковый фильтр</div><div>Кликните «Создать фильтр».</div></div>',
                        params: {
                            enabledButtons: {}
                        },
                        before: type => {
                            if (type === 'previous') {
                                return new Promise(resolve => {
                                    this.$modal.close()
                                    setTimeout(resolve)
                                })
                            }

                            return Promise.resolve()
                        }
                    },
                    {
                        target: '[data-v-step="filter-name-input"]',
                        content: '<div class="vue-tour-step"><div class="vue-tour-step__title">Назовите ваш фильтр</div><div style="margin-bottom: 8px"><div>Например, «Фильтр 1» или «Продажи».</div><div>Можно задать любое название для вашего фильтра.</div></div><div>Назвали фильтр? Переходим к следующему пункту.</div></div>',
                        params: {
                            placement: 'right',
                            enabledButtons: {}
                        },
                        before: type => {
                            if (type === 'next') {
                                return new Promise(resolve => {
                                    this.openCreateFilterModal()
                                    setTimeout(resolve)
                                })
                            }

                            return Promise.resolve()
                        }
                    },
                    {
                        target: '[data-v-step="filter-keywords-multiple-input"]',
                        content: `<div class="vue-tour-step vue-tour-step_wide">${keywordsHintComponent.$el.outerHTML}</div>`,
                        params: {
                            placement: 'bottom',
                            enabledButtons: {}
                        },
                    },
                    {
                        target: '[data-v-step="filter-exclude-words-multiple-input"]',
                        content: `<div class="vue-tour-step vue-tour-step_wide">${excludeWordsHintComponent.$el.outerHTML}</div>`,
                        params: {
                            placement: 'bottom',
                            enabledButtons: {}
                        },
                    },
                    {
                        target: '[data-v-step="filter-region-multiple-input"]',
                        content: `<div class="vue-tour-step vue-tour-step_wide">${regionWordsHintComponent.$el.outerHTML}</div>`,
                        params: {
                            placement: 'bottom',
                            enabledButtons: {}
                        },
                    },
                    {
                        target: '[data-v-step="create-filter-confirm-button"]',
                        content: `
                                  <div class="vue-tour-step">
                                      <div class="vue-hint-margin">Сохраните ваш фильтр и посмотрите какие закупки нашла система.</div>
                                      <div>Вы всегда сможете вернуться в настройки фильтра, и откорректировать его работу.</div>
                                  </div>
                        `,
                        params: {
                            enabledButtons: {
                                buttonNext: false,
                            },
                            placement: 'top',
                        },
                    },
                    {
                        target: `[data-v-step="filter-tab-last"]`,
                        content: `
                                  <div class="vue-tour-step">
                                      <div class="vue-hint-margin">Созданный вами фильтр отобразится здесь.</div>
                                      <div>Вы можете создать любое количество фильтров и в дальнейшем просто переключаться между ними.</div>
                                  </div>
                        `,
                        params: {
                            enabledButtons: {
                                buttonPrevious: false,
                            },
                            placement: 'right',
                        },
                    },
                    {
                        target: `[data-v-step="table-settings-button"]`,
                        content: `
                                  <div class="vue-tour-step">Вы можете настроить таблицу для максимально комфортного использования.</div>
                        `,
                        params: {
                            placement: 'top',
                            enabledButtons: {}
                        },
                        before: type => {
                            if (type === 'previous') {
                                return new Promise(resolve => {
                                    this.closePurchasesTableSettings()
                                    setTimeout(resolve)
                                })
                            }
                            return Promise.resolve()
                        },
                    },
                    {
                        target: `[data-v-step="column-setting-step"]`,
                        content: `
                                  <div class="vue-tour-step">Поставьте или снимите галочки для отображения нужных вам колонок.</div>
                        `,
                        params: {
                            placement: 'left',
                            enabledButtons: {}
                        },
                        before: (type) => {
                            if (type === "next") {
                                return new Promise((resolve) => {
                                    this.openPurchasesTableSettings()
                                    setTimeout(resolve, 350)
                                })
                            }

                            return Promise.resolve()
                        }
                    },
                    {
                        target: `[data-v-step="row-height-menu"]`,
                        content: `<div class="vue-tour-step">Чтобы поменять высоту строки, кликните сюда.</div>`,
                        params: {
                            placement: 'left',
                            enabledButtons: {}
                        },
                    },
                    {
                        target: `[data-v-step="toggle-active-purchases"]`,
                        content: `
                                 <div class="vue-tour-step">
                                     <div class="vue-hint-margin">С помощью данного переключателя, вы можете отобразить истекшие закупки.</div>
                                     <div>На сегодняшний день сервис хранит истекшие закупки 30 дней.</div>
                                 </div>
                        `,
                        params: {
                            placement: 'left',
                            enabledButtons: {}
                        },
                        before: type => {
                            if (type === 'previous') {
                                return new Promise(resolve => {
                                    this.openPurchasesTableSettings()
                                    setTimeout(resolve, 350)
                                })
                            }
                            return Promise.resolve()
                        }
                    },
                    {
                        target: `div.ag-header-row.ag-header-row-column > div:nth-child(5) > div.ag-header-cell-resize`,
                        content: `
                                 <div class="vue-tour-step">
                                     <div class="vue-hint-margin">Вы можете изменять ширину колонок, потянув за разделительную полоску в шапке колонки.</div>
                                     <div class="vue-hint-margin">Вы можете поменять расположение колонок.</div>
                                     <div>Зажмите кликом название колонки и переместите ее вправо или влево.</div>
                                 </div>
                        `,
                        params: {
                            placement: 'bottom',
                            enabledButtons: {}
                        },
                        before: type => {
                            if (type === 'next') {
                                return new Promise(resolve => {
                                    this.closePurchasesTableSettings()
                                    setTimeout(resolve)
                                })
                            }

                            return Promise.resolve()
                        }
                    },
                    {
                        target: `[data-v-step="start-tour-link"]`,
                        content: `
                                  <div class="vue-tour-step">
                                      <div class="vue-hint-margin">Вы всегда можете посмотреть подсказки еще раз.</div>
                                      <div>Они здесь.</div>
                                  </div>
                        `,
                        params: {
                            placement: 'bottom',
                            enabledButtons: {}
                        },
                    },
                ]
            },
            vueTourOptions() {
                return {
                    useKeyboardNavigation: false,
                    labels: {
                        buttonSkip: 'Пропустить',
                        buttonPrevious: 'Предыдущая',
                        buttonNext: 'Следующая',
                        buttonStop: 'Финиш'
                    }
                }
            },
        },
        methods: {
            ...mapActions('purchases', ['getPurchases']),
            ...mapActions('columnsFilters', ['resetColumnsFilters']),
            ...mapActions('columnsSort', ['resetColumnSort']),
            ...mapActions('filterTabs', ['setZeroAllLots', 'setCurrentTab']),
            ...mapActions('profile', ['updateHintsEnabled']),
            openPurchasesTableSettingsWithHint() {
                this.openPurchasesTableSettings()
                if (this.$tours['startTour'].currentStep === 7) {
                    this.$tours['startTour'].nextStep()
                }
            },
            openPurchasesTableSettings() {
                this.isSettingsOpen = true
            },
            openCreateFilterModalWithHint() {
                this.openCreateFilterModal()
                if (this.$tours['startTour'].currentStep === 0) {
                    this.$tours['startTour'].stop()
                }
            },
            openCreateFilterModal() {
                if (!this.authorities.includes('ROLE_SUBSCRIBED_USER')) {
                    return false
                }

                this.$modal.show(
                    PurchasesTableCustomFilterModal,
                    {
                        formData: {},
                        mode: 'Create'
                    },
                    PurchasesTableCustomFilterModalProperties
                )
            },
            closePurchasesTableSettingsWithHint() {
                this.closePurchasesTableSettings()
                if (this.$tours['startTour'].currentStep === 11) {
                    this.$tours['startTour'].nextStep()
                }
                if (this.$tours['startTour'].currentStep > 7 && this.$tours['startTour'].currentStep < 11) {
                    this.$tours['startTour'].start('7')
                }

            },
            closePurchasesTableSettings() {
                this.isSettingsOpen = false
            },
            onChangePagination() {
                this.$router.push(`${purchasesPath}/${this.currentTab.id}?page=${0}`)
                this.setZeroAllLots(this.currentTab)
            },
            onChangePage(page) {
                this.$router.push(`${purchasesPath}/${this.currentTab.id}?page=${page}`)
                this.setZeroAllLots(this.currentTab)
            },
            onClickDraggableFilterTabsBtn() {
                this.isDraggableTabFiltersMenuOpen = !this.isDraggableTabFiltersMenuOpen
            },
            closeDraggableTabFiltersMenu() {
                this.isDraggableTabFiltersMenuOpen = false
            },
            onChangeTab(tab) {
                if (parseInt(this.$route.params.id) === tab.id && isEmpty(this.$route.query)) {
                    this.setCurrentTab(tab)
                    return
                }
                this.$router.push(`${purchasesPath}/${tab.id}`)
                this.setCurrentTab(tab)
            },
            onSkip() {
                this.updateHintsEnabled({id: this.profileId, hintsEnabled: false})
            },
            onFinish() {
                this.updateHintsEnabled({id: this.profileId, hintsEnabled: false})
            },
        },

        watch: {
            hintsEnabled(newVal) {
                if (newVal && this.authorities.includes('ROLE_SUBSCRIBED_USER')) {
                    this.$tours['startTour'].start()
                }
            }
        },

        created() {

            eventBus.$on('closePurchaseTableSettingsMenu', this.closePurchasesTableSettings)

            this.$watch(vm => [vm.currentTab, vm.columnsFilters, vm.columnSort, vm.isActiveSearch, vm.$route.params.id, vm.$route.query.page], async (newVal) => {

                eventBus.$emit('resetFilterInput')

                try {
                    await this.getPurchases({
                        url: getPurchasesUrl(newVal[0].filterType),
                        profileId: this.profileId,
                        customFilters: mapColumnsFilters(newVal[1]),
                        currentTab: newVal[0],
                        isActiveSearch: newVal[3],
                        size: this.pagination.value,
                        sort: newVal[2],
                        filterId: newVal[0].id,
                        page: newVal[5] ?? 0
                    })

                    if (newVal[0] && !this.mindControlledUser) {
                        api.filtersTabs.editFilterTab(newVal[0].id, {newLots: 0})
                    }

                } catch (e) {
                    this.$toast.error(e.message)
                }

            })
        },

        mounted() {
            if (this.hintsEnabled && this.authorities.includes('ROLE_SUBSCRIBED_USER')) {
                this.$tours['startTour'].start()
            }

            let tab = this.filterTabByParam('filterType', 'ALL')

            const parsedNum = parseInt(this.$route.params.id)

            if (parsedNum) {
                const tabById = this.filterTabByParam('id', parsedNum)
                if (tabById && (this.authorities.includes('ROLE_SUBSCRIBED_USER') || tabById.filterType !== 'GENERAL')) {
                    tab = tabById
                }

            }
            this.setCurrentTab(tab)
        },
        beforeDestroy() {
            eventBus.$off('closePurchaseTableSettingsMenu')
            this.$tours['startTour'].stop()
            this.resetColumnsFilters()
            this.resetColumnSort()
        },
    }
</script>

<style lang="less">
    @import '../assets/less/variables.less';

    *{
        .marker-main-color{
            background-color: @warning-color;
        }
    }

    .purchases {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-table-wrapper {
            height: calc(100vh - 32px - 48px - 48px - 1px);
        }

        &-footer {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 48px;
            right: 0;
            bottom: 0;
            background-color: #ffffff;
            box-shadow: 0 -2px 4px 0 rgba(38, 50, 57, 0.24);

            .container{
                display: flex;
                .userInfo{
                    padding: 15px 100px;
                    color: @text-color-lighter;
                    display: flex;
                    gap: 10px;
                }
            }
        }
    }

    .section-header {
        display: flex;
        justify-content: space-between;

        .btn {
            flex-grow: 0;
            flex-shrink: 0;
        }
    }

    .table-settings-menu-enter,
    .table-settings-menu-leave-active {
        transform: translateX(150px);
        opacity: 0;
    }

    .table-settings-menu-enter-active {
        transition: all .3s ease;
    }

    .table-settings-menu-leave-active {
        transition: all .3s ease;
    }

    .draggable-list-btn-container {
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
        margin-bottom: 16px;
    }

    .dropdown-btn-container {
        position: relative;
        z-index: 2;
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;
        justify-content: flex-end;
        background-color: white;

        padding-left: 24px;

        &::after {
            content: "";
            position: absolute;
            left: 100%;
            top: 0;
            bottom: 0;
            z-index: 1;
            background-color: white;
            width: 24px;
        }
    }

</style>
