export default {
    'oneRow': {
        height: 48,
        text: 'Маленькая (1 строка)'
    },
    'twoRows': {
        height: 62,
        text: 'Комфортная (2 строки)'
    },
    'threeRows': {
        height: 93,
        text: 'Большая (3 строки)'
    }
}
