<template>
    <v-tour :name="tourName" :steps="steps" :callbacks="callbacks" :options="options">
        <template slot-scope="tour">
            <v-step
                    v-if="tour.steps[tour.currentStep]"
                    :key="tour.currentStep"
                    :step="tour.steps[tour.currentStep]"

                    :labels="tour.labels"
            >
                <template slot="header">
                    <vue-tour-header
                            :hint-number="tour.currentStep"
                            :hints-count="tour.steps.length"
                            :skip="tour.skip"
                    />
                </template>
                <template slot="content">
                    <vue-tour-content
                            :content="tour.steps[tour.currentStep].content"
                    />
                </template>
                <template slot="actions">
                    <vue-tour-actions
                            :previous-step="tour.previousStep"
                            :next-step="tour.nextStep"
                            :finish-step="tour.finish"
                            :is-last-step="tour.isLast"
                            :is-first-step="tour.isFirst"
                            :enabledButtons="{...tour.enabledButtons, ...tour.steps[tour.currentStep].params.enabledButtons}"/>
                </template>
            </v-step>
        </template>
    </v-tour>
</template>

<script>
    import VueTourActions from "./VueTourActions";
    import VueTourHeader from "./VueTourHeader";
    import VueTourContent from "./VueTourContent";

    export default {
        name: "VueTour",
        components: {VueTourContent, VueTourHeader, VueTourActions},
        props: {
            steps: {
                type: Array,
                required: true
            },
            tourName: {
                type: String,
                required: true,
            },
            options: {
                type: Object,
                required: false,
            },
            callbacks: {
                type: Object,
                required: false,
            }
        },
    }
</script>

<style scoped lang="less">
</style>