<template>
    <div class="header-container">
        <span class="header-container-text">
            {{params.displayName}}
        </span>
        <span v-if="params.customSort" class="header-container-icon" @click="onChangeSort">
            <span v-if="!columnSortByKey(this.fieldPath)" key="ico-sort">
                <ico name="icoSort" size="16"/>
            </span>
            <span v-else-if="columnSortByKey(fieldPath).sortType === 'DESC'" key="ico-arrow-downward">
                <ico name="icoArrowDownward" size="16"/>
            </span>
            <span v-else key="ico-arrow-upward">
                <ico name="icoArrowUpward" size="16"/>
            </span>
        </span>
    </div>
</template>

<script>
    import Vue from 'vue'
    import {mapActions, mapGetters} from "vuex"
    import {isEmpty} from "lodash"
    import {purchasesPath} from "@/router/purchases"

    export default Vue.extend({
        name: "CustomSortingHeaderComponent",
        data() {
            return {
                sortingPath: null,
                fieldPath: null,
            }
        },
        computed: {
            ...mapGetters('columnsSort', ['columnSortByKey']),
            ...mapGetters('filterTabs', ['currentTab']),
        },
        methods: {
            ...mapActions('columnsSort', ['setColumnSort', 'removeColumnSort']),
            ...mapActions('filterTabs', ['setZeroAllLots']),
            onChangeSort() {

                const columnSort = this.columnSortByKey(this.fieldPath)

                if (columnSort) {
                    if (columnSort.sortType === 'DESC') {
                        this.setColumnSort({
                            fieldPath: this.fieldPath,
                            sortingPath: this.sortingPath,
                            sortType: 'ASC',
                        })
                    } else {
                        this.removeColumnSort(this.fieldPath)
                    }
                } else {
                    this.setColumnSort({
                        fieldPath: this.fieldPath,
                        sortingPath: this.sortingPath,
                        sortType: 'DESC',
                    })
                }

                this.pushRoute()
            },
            pushRoute() {
                if (parseInt(this.$route.params.id) === this.currentTab.id && isEmpty(this.$route.query)) {
                    this.setZeroAllLots(this.currentTab)
                    return
                }
                this.$router.push(`${purchasesPath}/${this.currentTab.id}`)
                this.setZeroAllLots(this.currentTab)
            }
        },

        created() {
            this.sortingPath = this.params.column.colDef.sortingPath
            this.fieldPath = this.params.column.colDef.field
        },
    })
</script>

<style scoped lang="less">

    .header-container {
        display: inline-flex;
        overflow: hidden;
        align-items: center;

        &-text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &-icon {
            padding-left: 5px;
        }
    }

</style>