<template>
    <div class="table-settings-menu" v-click-outside="closePurchasesTableSettings" :style="cssVars">
        <div class="table-settings-menu-header">
            <button type="button" @click="closePurchasesTableSettings">
                <ico name="icoChevronRight" color="#79919C"></ico>
            </button>
            <p>Настройки таблицы</p>
        </div>
        <div class="table-settings-menu-content">
            <div class="table-settings-menu-item">
                <ToggleActivePurchasesBtn/>
                <ToggleMarkerBtn />
            </div>
            <div class="table-settings-menu-item table-settings-columns">
                <p class="table-settings-menu-item-title">Настройка колонок</p>
                <draggable
                        v-model="columns"
                        :move="onMove"
                        class="table-settings-menu-columns"
                >
                    <ToggleColumnVisibilityBtn
                            v-for="(column, i) in columns"
                            :column="column"
                            :key="'column_'.concat(i).concat('_').concat(column.field)"
                            :data-v-step="i === 0 ? 'column-setting-step' : 'column-setting-none'"
                    />
                </draggable>
            </div>
            <div class="table-settings-menu-item">
                <p class="table-settings-menu-item-title">Высота строк</p>
                <ChangeRowsHeightMenu data-v-step="row-height-menu"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import {eventBus} from '@/main'
    import draggable from 'vuedraggable'
    import ToggleColumnVisibilityBtn from '@/components/purchasesTableSettings/ToggleColumnVisibilityBtn'
    import ChangeRowsHeightMenu from '@/components/purchasesTableSettings/ChangeRowsHeightMenu'
    import ToggleActivePurchasesBtn from "@/components/purchasesTableSettings/ToggleActivePurchasesBtn"
    import ToggleMarkerBtn from "@/components/purchasesTableSettings/ToggleMarkerBtn"
    import {moveColumns, sortedTableColumns} from '@/utils/agGreed/tableSettingsHelper'
    import {agGridColumnsDefs} from "@/utils/agGreed/agGridColumnsDefs"
    import {slice, values} from "lodash"

    const OFFSET = 2

    export default {
        name: "PurchasesTableSettings",
        data() {
            return {
                movedColumn: {
                    oldIndex: null,
                    toIndex: null,
                    columnName: '',
                }
            }
        },
        components: {
            ToggleColumnVisibilityBtn,
            ToggleActivePurchasesBtn,
            ToggleMarkerBtn,
            ChangeRowsHeightMenu,
            draggable,
        },
        computed: {
            ...mapGetters('purchasesTableSettings', ['purchasesTableColumns', 'getColumnProperty']),
            columns: {
                get() {
                    return slice(values(sortedTableColumns(this.purchasesTableColumns, agGridColumnsDefs)), OFFSET)
                },
                set() {
                    const updatedColumns = moveColumns(this.purchasesTableColumns, this.movedColumn)
                    this.updatePurchaseTableColumns(updatedColumns)
                    eventBus.$emit('moveColumnByIndex', this.movedColumn.oldIndex, this.movedColumn.toIndex)
                    this.movedColumn.oldIndex = null
                    this.movedColumn.toIndex = null
                    this.movedColumn.columnName = ''
                }
            },
            cssVars() {
                return {
                    '--rows': Math.round(this.columns.length / 2)
                }
            }
        },
        methods: {
            ...mapActions('purchasesTableSettings', ['updatePurchaseTableColumns']),
            closePurchasesTableSettings() {
                this.$emit('closePurchasesTableSettings')
            },
            onMove(e) {
                this.movedColumn.columnName = e.draggedContext.element.field
                this.movedColumn.oldIndex = this.getColumnProperty(this.movedColumn.columnName, 'index')
                this.movedColumn.toIndex = e.draggedContext.futureIndex + OFFSET
            },
            currentVueTourStep(tourName) {
                return this.$tours[tourName].currentStep
            },
        },
    }
</script>

<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    .table-settings-menu {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 3;
        padding-bottom: 24px;
        width: 576px;
        text-align: left;
        background-color: @bg-color-light;
        @media(max-height: 900px) {
            padding-bottom: 6px;
        }

        &-header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 24px;
            padding: 16px 24px;
            background-color: @bg-color-middle;

            @media(max-height: 900px) {
                margin-bottom: 6px;
            }

            button {
                display: block;
                margin-right: 8px;
                height: 24px;
            }

            p {
                font-weight: 500;
                font-size: 14px;
                letter-spacing: 0.3px;
            }
        }

        &-content {
            overflow-x: hidden;
            overflow-y: auto;
            padding: 0 24px;
            height: calc(100% - 80px);

            @media(max-height: 900px) {
                height: calc(100% - 64px);
            }

            &::-webkit-scrollbar {
                width: 8px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                width: 8px;
                background-color: fade(#000000, 26%);

                &:hover {
                    background-color: fade(#000000, 30%);
                }
            }
        }

        &-item {
            margin-bottom: 16px;
            border-bottom: 2px solid @text-color-lighter;
            padding-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
                border-bottom: none;
                padding-bottom: 0;
            }

            &-title {
                margin-bottom: 16px;
                font-weight: 500;
                font-size: 14px;
                letter-spacing: 0.3px;
            }
        }

        &-columns {
            height: calc(100% - 40px);
            display: grid;
            grid-template-rows: repeat(var(--rows), 1fr);
            grid-auto-flow: column;
            column-gap: 16px;
        }
    }
    .table-settings-columns {
        height: calc(100% - 118px - 92px - 4px);
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>
