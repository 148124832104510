<template>
    <div class="vue-tour-content-container" :style="cssVars">
        <div v-html="content"></div>
    </div>
</template>

<script>
    export default {
        name: "VueTourContent",
        props: {
            content: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                clientHeight: 420,
            }
        },
        computed: {
            cssVars() {
                let maxHeight = '420px'
                if (this.$tours['startTour'].currentStep === 2) {
                    maxHeight = `${this.clientHeight / 2 - 50}px`
                }
                if (this.$tours['startTour'].currentStep === 3) {
                    maxHeight = `${this.clientHeight / 2 - 75}px`
                }
                if (this.$tours['startTour'].currentStep === 4) {
                    maxHeight = `${this.clientHeight / 2 - 100}px`
                }
                return {
                    '--max-height': maxHeight
                }
            }
        },
        mounted() {
            this.clientHeight = this.$root?.$children[0]?.$children[0]?.$children[1]?.$el?.clientHeight ?? 420
        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/less/variables";

    .vue-tour-content-container {
        padding: 16px 0;
        border-top: 1px solid #3b4346;
        border-bottom: 1px solid #3b4346;

        max-height: var(--max-height);
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            border-radius: @br;
            width: 12px;
            background-color: #ffffff;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: @br;
            width: 12px;
            background-color: fade(#000000, 24%);
            &:hover {
                background-color: fade(#000000, 30%);
            }
        }
    }
</style>