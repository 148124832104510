import store from '@/store'

localStorage.setItem("marker", store.getters["purchasesTableSettings/isKeywordShown"]);

export const highlightSubstring = (option, filterParams) => {
  let res;
  const marker = JSON.parse(localStorage.getItem("marker"));
  if (marker == true) {
    if (filterParams?.length) {
      let options = option;
      filterParams.map((item) => {
        item = item.trim()
        let regExp = "";
        if (item.split(" ").length > 1) {
          item.split(" ").map((item) => {
            item = item
              .toLocaleLowerCase()
              .replace(/\*/g, "[А-Яа-я]*[a-zA-Z]*.?\\s?")
              regExp += `([${item[0].toLocaleLowerCase()}${item[0].toUpperCase()}]${item.substring(1)}|[${item[0].toLocaleLowerCase()}${item[0].toUpperCase()}]${item.toUpperCase().substring(1)}).?`;
          });
          if (options.match(new RegExp(regExp, "gm")) !== null) {
            let match;
            match = options?.match(new RegExp(regExp, "gm"))[0]
            item.split(" ").map((value) => {
              if(value.length > 1){
                item = value.replace(/\*/g, "").toLocaleLowerCase();
                item = `([${item[0].toLocaleLowerCase()}${item[0].toUpperCase()}]${item.substring(1)}|[${item[0].toLocaleLowerCase()}${item[0].toUpperCase()}]${item.toUpperCase().substring(1)})`;
                match = match.replace(new RegExp(item, "gm"), (item) => {
                  return `<mark class="marker-main-color">${item}</mark>`;
                });
              }else{
                item = value.replace(/\*/g, "").toLocaleLowerCase();
                item = `\\s[${item}${item.toUpperCase()}]\\s`
                match = match.replace(new RegExp(item, "gm"), () => {
                  return ` <mark class="marker-main-color">${value}</mark> `;
                });
              }
              
            });
            options = options.replaceAll(new RegExp(regExp, "gm"), match);
            res = options;
          } else {
            res = options;
          }
        } else {
          if(options.split(' ').length === 1){
            let a = `([${item[0].toLocaleLowerCase()}${item[0].toUpperCase()}]${item.substring(1).toUpperCase()}|[${item[0].toUpperCase()}${item[0].toLocaleLowerCase()}]${item.substring(1)})`
            options = options.replace(new RegExp(a.replaceAll(/\*/g, ""), "gm"), (option) =>  `<mark class="marker-main-color">${option}</mark>`)
          }
          if(item.includes('*')){
            item = item.replace(/\*/g, "");
            // const regExpEx = `([${item[0].toUpperCase()}${item[0].toLocaleLowerCase()}]${item.substring(1).toUpperCase()}|[${item[0].toUpperCase()}${item[0].toLocaleLowerCase()}]${item.substring(1)})`
            let regExpEx;
            let arr = item.split('')
            for(let i = 0; i < arr.length; i++){
              arr[i] = `[${arr[i].toLocaleLowerCase()}${arr[i].toUpperCase()}]`
              regExpEx = arr.join('')
            }

            regExp = `\\s{1}${regExpEx}`;
            options = options.replaceAll(
              new RegExp(regExp, "gm"),
              (item) => ` <mark class="marker-main-color">${item.trim()}</mark>`
            );
            options = options.replaceAll(
              new RegExp(item.toUpperCase(), "gm"),
              (item) => ` <mark class="marker-main-color">${item.trim()}</mark>`
            );
            regExp = `[-",./'_)(\\s]?${regExpEx}`;
            options = options.replaceAll(
              new RegExp(regExpEx, "gm"),
              (item) => `<mark class="marker-main-color">${item.trim()}</mark>`
            );
          }else{
            // const regExpEx = `([${item[0].toUpperCase()}${item[0].toLocaleLowerCase()}]${item.substring(1).toUpperCase()}|[${item[0].toUpperCase()}${item[0].toLocaleLowerCase()}]${item.substring(1)}|[${item[0].toUpperCase()}${item[0].toLocaleLowerCase()}]${item.substring(1).toLocaleLowerCase()})`
            let regExpEx;
            let arr = item.split('')
            for(let i = 0; i < arr.length; i++){
              arr[i] = `[${arr[i].toLocaleLowerCase()}${arr[i].toUpperCase()}]`
              regExpEx = arr.join('')
            }
            regExp = `[-",'_)(\\s]?${regExpEx}[-",'_)(\\s]`;
            let match;
            if(options?.match(new RegExp(regExp, "gm"))){
              match = options?.match(new RegExp(regExp, "gm"))[0]
              match = match.replaceAll(
                new RegExp(`${regExpEx}`, "gm"),
                (item) => `<mark class="marker-main-color">${item.trim()}</mark>`
              );
              options = options.replaceAll(new RegExp(regExp, "gm"), match);
            }
            regExp = `[-",'_)(\\s]{1}${regExpEx}$`;

            if(options?.match(new RegExp(regExp, "gm"))){
              match = options?.match(new RegExp(regExp, "gm"))[0]
              match = match.replaceAll(
                new RegExp(`${regExpEx}`, "gm"),
                (item) => `<mark class="marker-main-color">${item.trim()}</mark>`
              );
              options = options.replaceAll(new RegExp(regExp, "gm"), match);
            }

            regExp = `^${regExpEx}[-",'_)(\\s]{1}`;

            if(options?.match(new RegExp(regExp, "gm"))){
              match = options?.match(new RegExp(regExp, "gm"))[0]
              match = match.replaceAll(
                new RegExp(`${regExpEx}`, "gm"),
                (item) => `<mark class="marker-main-color">${item.trim()}</mark>`
              );
              options = options.replaceAll(new RegExp(regExp, "gm"), match);
            }
          }
          res = options;
        }
      });
      return res;
    }
    return option;
  }
  return option;
};