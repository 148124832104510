<template>
    <div class="keywords-hint">
        <div class="vue-tour-step__title">
            Введите ключевые слова или словосочетания, соответствующие вашему поисковому запросу
        </div>
        <div>
            Ввод ключевого слова или фразы завершайте нажатием клавиши «Enter»
        </div>
        <hint-body>
            <template v-slot:header>
                <div class="keywords-hint-header">
                    <div>
                        Сервис осуществляет поиск по дословному совпадению.
                    </div>
                    <div>
                        Смотрите пример.
                    </div>
                </div>
            </template>
            <template v-slot:content>
                <div>
                    Если ввести:
                </div>
                <div class="keywords-hint__hints-container">
                    <div class="keywords-hint__hints-container__hint">
                        <div class="keywords-hint__hints-container__hint__label">
                            <hint-label label="мас"/>
                        </div>
                        <div class="keywords-hint__hints-container__hint__options">
                            <div>
                                сервис будет искать закупки, в которых присутствует слово
                                <span class="vue-tour-hint">
                                    мас
                                </span>
                                и скорее всего ничего не найдет, т.к.
                                сервис НЕ подставляет окончания самостоятельно.
                                <div>
                                    Можно прописать все возможные формы слова:
                                    <hint-label label="маска"/>
                                    <hint-label label="маски"/>
                                    <hint-label label="масок"/>
                                    или применить &laquo;умный поиск&raquo;.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="keywords-hint__hints-container__hint">
                        <div class="keywords-hint__hints-container__hint__label">
                            <div class="custom-label">!</div>
                        </div>
                        <div class="keywords-hint__hints-container__hint__options">
                            <div>
                                Для &laquo;умного&raquo; поиска всех возможных форм слова используйте символ
                                <span>
                                    <img src="../../assets/img/asterisk-14.png"
                                         style="filter: invert(88%) sepia(32%) saturate(1894%) hue-rotate(323deg) brightness(101%) contrast(101%);">
                                </span>
                            </div>
                            <div>
                                Этот символ позволяет системе автоматически подставлять все возможные окончания к
                                написанному слову.
                            </div>
                        </div>
                    </div>
                    <div class="keywords-hint__hints-container__hint">
                        <div class="keywords-hint__hints-container__hint__label">
                            <hint-label label="мас*"/>
                        </div>
                        <div class="keywords-hint__hints-container__hint__options">
                            <div class="keywords-hint__hints-container__hint__options__title">
                                сервис найдет закупки, у которых по тексту встречаются слова, начинающиеся на <span
                                    class="vue-tour-hint">мас</span>:
                            </div>
                            <div>
                                <test-purchase-hint
                                        v-for="(purchase, i) in testPurchasesSetOne"
                                        :key="'testPurchaseOne'.concat(i)"
                                        :number="purchase.number"
                                        :price="purchase.price"
                                        :proposal-name="purchase.proposalName"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="keywords-hint__hints-container__hint">
                        <div class="keywords-hint__hints-container__hint__label">
                            <hint-label label="зак мас"/>
                        </div>
                        <div class="keywords-hint__hints-container__hint__options">
                            сервис закупок не найдет, т.к. НЕ подставляет окончания самостоятельно и будет искать
                            закупки с дословным совпадением <span class="vue-tour-hint">зак</span>&nbsp;
                            <span class="vue-tour-hint">мас</span>.
                        </div>
                    </div>
                    <div class="keywords-hint__hints-container__hint">
                        <div class="keywords-hint__hints-container__hint__label">
                            <hint-label label="зак* мас*"/>
                        </div>
                        <div class="keywords-hint__hints-container__hint__options">
                            <div class="keywords-hint__hints-container__hint__options__title">
                                сервис найдет закупки:
                            </div>
                            <div>
                                <test-purchase-hint
                                        v-for="(purchase, i) in testPurchasesSetTwo"
                                        :key="'testPurchaseTwo'.concat(i)"
                                        :number="purchase.number"
                                        :price="purchase.price"
                                        :proposal-name="purchase.proposalName"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    Если вы ввели ключевые слова, но система не отобразила закупки, то вы можете всегда вернуться к этой
                    подсказке, кликнув на значок
                    <span>
                        <ico name="icoHelpOutline" color="#FFB645" size="14"/>
                    </span>
                    в редакторе фильтра или посмотреть подсказки еще раз, кликнув
                    <span>
                        <ico name="icoFlag" color="#FFB645" size="14"/>
                    </span>
                    на левой панели.
                </div>
            </template>
        </hint-body>
    </div>
</template>

<script>
    import HintLabel from "./HintLabel";
    import TestPurchaseHint from "./TestPurchaseHint";
    import HintBody from "./HintBody";

    export default {
        name: "KeyWordsHint",
        components: {HintBody, TestPurchaseHint, HintLabel},
        data() {
            return {
                testPurchasesSetOne: [
                    {
                        proposalName: 'закупка <span class="vue-tour-hint">мас</span>ляного фильтра',
                        price: '800,00 BYN',
                        number: '40 шт.'
                    },
                    {
                        proposalName: 'приобретение медицинских <span class="vue-tour-hint">мас</span>ок',
                        price: '6300,00 BYN',
                        number: '63000 шт.'
                    },
                    {
                        proposalName: '<span class="vue-tour-hint">мас</span>ло подсолнечное',
                        price: '1440,00 BYN',
                        number: '3200 кг.'
                    },
                ],
                testPurchasesSetTwo: [
                    {
                        proposalName: '<span class="vue-tour-hint">зак</span>упка <span class="vue-tour-hint">мас</span>ок',
                        price: '6300,00 BYN',
                        number: '63000 шт.'
                    },
                    {
                        proposalName: '<span class="vue-tour-hint">зак</span>рытие <span class="vue-tour-hint">мас</span>карада',
                        price: '2200,00 BYN',
                        number: '1 услуга'
                    },
                    {
                        proposalName: '<span class="vue-tour-hint">зак</span>аз <span class="vue-tour-hint">мас</span>лобака',
                        price: '1800,00 BYN',
                        number: '1 единица'
                    },
                ],
            }
        }
    }
</script>

<style scoped lang="less">

    .keywords-hint {

        width: 650px;

        &-header {
            margin-bottom: 8px;
        }

        &__hints-container {
            padding: 8px 0;

            &__hint {
                display: flex;
                padding: 2px 0;
                margin-bottom: 16px;

                &__label {
                    display: flex;
                    justify-content: flex-end;
                    min-width: 96px;
                    padding-right: 6px;

                }

                &__options {
                    width: 100%;

                    &__title {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .custom-label {
        font-size: 36px;
        color: #FFB645;
        font-weight: 900;
        padding-right: 20px;
    }

</style>