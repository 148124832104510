import {merge, values, cloneDeep, map, keyBy} from 'lodash'

const combineTableSettingAgGridColumns = (purchasesTableColumns, agGridColumnsDefs, resolutionMultiplier = 1) => {

    const copiedPurchasesTableColumns = cloneDeep(purchasesTableColumns)

    return values(merge(keyBy(map(copiedPurchasesTableColumns, (it) => ({...it, width: Math.round(it.width * resolutionMultiplier)})), 'field'), agGridColumnsDefs))
}

export const sortedTableColumns = (purchasesTableColumns, agGridColumnsDefs, resolutionMultiplier) => {
    const combinedColumns = combineTableSettingAgGridColumns(purchasesTableColumns, agGridColumnsDefs, resolutionMultiplier)
    return combinedColumns.sort((a,b) => a.index - b.index)
}

export const moveColumns = (columns, movedColumn) => {
    const updatedColumns = cloneDeep(columns)
    const currentColumn = updatedColumns[movedColumn.columnName]
    const diff = (movedColumn.oldIndex - movedColumn.toIndex)
    const sign = diff / (diff < 0 ? -diff : diff)
    for (let key in updatedColumns) {
        const index = updatedColumns[key].index
        if ((index - movedColumn.toIndex) * sign >= 0 && (index - movedColumn.oldIndex) * sign <= 0) {
            updatedColumns[key].index += sign
        }
    }
    currentColumn.index = movedColumn.toIndex
    return updatedColumns
}
