<template>
  <div class="container">
    <button
      class="toggle-btn"
      :class="{ active: isKeywordShown }"
      type="button"
      @click="toggleIsActive()"
      data-v-step="toggle-marker-purchases"
    >
      <span></span>
      <span>Подсветить ключевые слова</span>
    </button>
    <Hint>
      <div class="hint-content" style="min-width: 300px">
        <p>
          При отключении данной опции в поисковой выдаче фильтра ключевые слова не будут выделены
        </p>
      </div>
    </Hint>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.common.js';
import Hint from '../common/Hint'

export default {
  name: "ToggleMarkerBtn",
  components: {
    Hint
  },
  data() {
    return {
      test: JSON.parse(localStorage.getItem('marker')),
      gridApi: null,
    };
  },
  computed: {
    ...mapGetters('purchasesTableSettings', ['isActiveSearch', 'isKeywordShown']),
    ...mapGetters('filterTabs', ['currentTab']),
  },
  methods: {
    ...mapActions('purchasesTableSettings', ['updateIsKeywordShown', 'updateIsActiveSearch']),
    
    toggleIsActive() {
      try {
        this.updateIsKeywordShown(!this.isKeywordShown);
        localStorage.setItem('marker', !this.isKeywordShown)
        this.updateIsActiveSearch({isActiveSearch: this.isActiveSearch, routeId: this.$route.params.id, routeQuery: this.$route.query})
      } catch (e) {
        this.$toast.error(e.message);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/less/variables.less";

.container{
    display: flex;
    gap: 10px;
}
p {
  letter-spacing: 0.3px;
  color: white;
}

.toggle-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;

  span {
    &:first-child {
      position: relative;
      display: block;
      margin-right: 16px;
      border-radius: 12px;
      width: 48px;
      height: 24px;
      background-color: @bg-color-middle;
      transition: @trans;

      &::before {
        content: "";
        position: absolute;
        left: 3px;
        top: 2px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: #ffffff;
        transition: @trans;
      }
    }
  }

  &.active {
    span:first-child {
      background-color: @bg-color-dark;

      &::before {
        left: 25px;
      }
    }
  }
}

.hint{
  left: 0;

  &-wrapper{
    margin-top: 3px;
  }
}
</style>
>>>>>>> 8b01dc2ef3a13c045e36e3b45c112ae0b813f9a6
