<template>
  <div></div>
  <!-- <div class="tooltip">
    <p>{{ formattedText }}</p>
    <template v-if="showLinkForBeloil">
        <div class="tooltip__link">Участие в данной закупке осуществляется на площадке
           <a href="https://sapsrm.beloil.by"  target="_blank">https://sapsrm.beloil.by</a>
        </div>
      <div class="tooltip__link">Инструкция для регистрации:
           <a href="https://www.youtube.com/watch?v=w-7lsm1-csY" target="_blank">https://www.youtube.com/watch?v=w-7lsm1-csY</a>
        </div>
    </template>
  </div> -->
</template>

<script>
import Vue from 'vue'
import {mapGetters} from "vuex"

export default Vue.extend({
  name: "CellTooltip",
  computed: {
    ...mapGetters('auth', ['authorities']),
    formattedText() {
      const text = this.params.value
      return typeof text === 'string' ? text.replace(/&quot;/g, '"').replace(/&gt;/g, '>') : text
    },
    showLinkForBeloil() {
      if(this.params?.data?.proposalSource)
      return this.params.data.proposalSource === "https://sapsrm.beloil.by" &&
         ( this.params.colDef.field === 'lot' || this.params.colDef.field === 'proposalNumber')
      return false
    }
  },
})
</script>

<style lang="less" scoped>
@import '../../../assets/less/variables.less';

.tooltip {
  position: absolute;
  border-radius: @br;
  padding: 4px 8px;
  max-width: 300px;
  letter-spacing: 0.3px;
  color: #ffffff;
  background-color: fade(@text-color-light, 90%);
  pointer-events: none;
  transition: opacity 1s;
}
.tooltip__link {
  color: #FFFFFF;
  margin-top: 4px;

  a {
    color: #F7F7F7;
  }
}

.tooltip.ag-tooltip-hiding {
  opacity: 0;
}
</style>
