<template>
    <div class="tabs">
        <Tab
                v-for="(tab, i) in filterTabs"
                :key="tab.id"
                :tab="tab"
                :is-active="currentTab && currentTab.id === tab.id"
                :is-editable="tab.editable"
                @onChangeTab="onChangeTab"
                :data-v-step="i === 3 ? 'filter-tab-last' : 'filter-tab'"
        />
    </div>
</template>

<script>
    import Tab from "@/components/common/Tab"
    import {mapActions, mapGetters} from "vuex"

    export default {
        name: "TabList",
        components: {
            Tab
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters('filterTabs', ['filterTabs', 'currentTab']),
        },
        methods: {
            ...mapActions('filterTabs', ['setCurrentTab']),
            onChangeTab(tab) {
                this.$emit('onChangeTab', tab)
            },
        },
    }
</script>

<style scoped lang="less">

    .tabs {
        width: calc(100% - 190px - 48px - 24px);
        flex-shrink: 1;
        flex-grow: 1;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 16px;
        border-bottom: 1px solid #eeeeee;

    }

</style>