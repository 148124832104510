<template>
    <div class="vue-tour-header-container">
        <div class="vue-tour-header-container__hint-counter">
            Подсказка {{ hintNumber + 1 }} из {{ hintsCount }}
        </div>
        <div>
            <button
                    class="vue-tour-header-container__skip-button vue-tour-actions-button button-transparent"
                    @click="skip"
            >
                Пропустить
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "VueTourHeader",
        props: {
            skip: {
                type: Function,
                required: false,
                default: () => {},
            },
            hintNumber: {
                type: Number,
                required: true,
            },
            hintsCount: {
                type: Number,
                required: true,
            },
        }
    }
</script>

<style scoped lang="less">
    @import '../../assets/less/variables.less';

    .vue-tour-header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 12px 0;

        &__hint-counter {
            color: @text-color-light;
        }

        &__skip-button {
            padding: 0;
            color: @text-color-light;
        }
    }
</style>