<template>
  <div class="proposal" ref="wrapper">
    <button
        v-if="params.value.lotsNumber > 1"
        class="proposal-hint-btn"
        @click="openOtherLotsModal"
        type="button"
    >
      <ico name="icoInfo" color="#79919C" size="24"></ico>
    </button>
    <span v-if="!showTooltip" ref="proposalName" v-html="highlight()"></span>
    <el-popover v-else
                class="tooltip"
                popper-class="text-tooltip"
                placement="top-start"
                trigger="hover">
      <p v-html="highlight()"></p>

      <template slot="reference">
        <p class="text-value"
           ref="proposalName"
           v-html="highlight()"></p>
      </template>
    </el-popover>
  </div>
</template>

<script>
import Vue from 'vue'
import OtherLotsModal from "@/components/purchaseTable/OtherLotsModal"
import {OtherLotsModalProperties} from "@/utils/modalsProperties";
import { highlightSubstring } from './Highlights';
import { mapGetters } from 'vuex/dist/vuex.common.js';

export default Vue.extend({
  name: "ProposalNameCellRendererFramework",
  data() {
    return {
      lots: [],
      showTooltip: false,
      resizeObserver: null
    }
  },
  computed: {
    ...mapGetters('filterTabs', ['currentTab', 'filterTabByParam']),
  },
  mounted() {
    setTimeout(() => {
      this.setVisibleValue()

      if (this.$refs?.wrapper) {
        this.resizeObserver = new ResizeObserver(() => {
          this.setVisibleValue()
        })
        this.resizeObserver.observe(this.$refs.wrapper)
      }
    }, 1000)
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.$refs.wrapper)
      this.resizeObserver = null
    }
  },
  methods: {
    highlight(){
      return highlightSubstring(this.params.value.proposalName, this.currentTab.searchCriteria.keyWords)
    },
    setVisibleValue() {
      const el = this.$refs.proposalName
      const elParent = this.$refs.wrapper
      if (el && elParent) {

        const paddingLrEl = (parseInt(window.getComputedStyle(elParent).paddingLeft) +
            parseInt(getComputedStyle(elParent).paddingRight))

        this.showTooltip = (elParent.clientHeight < el.offsetHeight) ||
            ((elParent.clientWidth - paddingLrEl) < el.offsetWidth) ||
            (el.scrollHeight > el.clientHeight) ||
            (el.scrollWidth > el.clientWidth)
      }
    },
    openOtherLotsModal() {
      this.$modal.show(
          OtherLotsModal,
          {
            lotId: this.params.value.lotID
          },
          OtherLotsModalProperties
      )
    },
  }
})
</script>

<style lang="less" scoped>
@import '../../../assets/less/variables.less';

.proposal {
  position: relative;
  padding-left: 28px;
}

.proposal-hint-btn {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -12px;
  width: 24px;
  height: 24px;
}
</style>